import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import UserValidation from '../../validation/userValidation';
import Select from '../../components/formElements/select/select';
import Wrapper from '../../components/UI/wrapper/wrapper';
import Checkbox from '../../components/formElements/checkbox/checkbox';
import HouseSelect from '../../components/formElements/select/houseSelect';

function CreateUsers() {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [houseNumber, setHouseNumber] = useState('')
    const [house, setHouse] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState('')
    const [houses, setHouses] = useState([])
    const [selectedHouses, setSelectedHouses] = useState([])
    const [permissionToAllHouses, setPermissionToAllHouses] = useState(false)
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles/all`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setHouses(res.data)
            })
        }
    }, [user])

    function clearState() {
        setName('')
        setEmail('')
        setPhone('')
        setAddress('')
        setHouseNumber('')
        setHouse('')
        setPassword('')
        setConfirmPassword('')
        setSelectedRole('')
        setSelectedHouses([])
        setPermissionToAllHouses(false)
        setInvalidFields([])
    }

    function handleSelectHouses(house) {
        if (selectedHouses && selectedHouses.filter(i => i.nimi === house.nimi).length > 0) {
            const index = selectedHouses.findIndex(i => i.nimi === house.nimi)
            setSelectedHouses(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedHouses(prevState => [...prevState, house])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = UserValidation(name, email, password, confirmPassword, selectedRole)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            email: email,
            phone: phone,
            address: address,
            house_number: houseNumber,
            house_id: house ? house : null,
            password: password,
            role_id: selectedRole,
            permission_to_all_houses: permissionToAllHouses,
            user_house_permissions: selectedHouses
        }

        axios.post(`${process.env.REACT_APP_API_PATH}/api/users`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then((res) => {
            if (res.data === 'User exists') {
                setMessage({ message: 'Käyttäjä on jo lisätty sähköpostiosoitteella ' + email, class: 'Error' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            } else {
                clearState()
                setMessage({ message: 'Käyttäjän lisääminen onnistui.', class: 'Success' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            }
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Lisää käyttäjä</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'name'
                    title = 'Nimi'
                    value = { name }
                    placeholder = 'Nimi'
                    handlechange = { (e) => setName(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'email'
                    title = 'Sähköpostiosoite (tai käyttäjätunnus, jos ei ole sähköpostiosoitetta)'
                    value = { email }
                    placeholder = 'Sähköpostiosoite (tai käyttäjätunnus, jos ei ole sähköpostiosoitetta)'
                    handlechange = { (e) => setEmail(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'phone'
                    title = 'Puhelinnumero'
                    value = { phone }
                    placeholder = 'Puhelinnumero'
                    handlechange = { (e) => setPhone(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'address'
                    title = 'Osoite'
                    value = { address }
                    placeholder = 'Osoite'
                    handlechange = { (e) => setAddress(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'houseNumber'
                    title = 'Asunnon numero'
                    value = { houseNumber }
                    placeholder = 'Asunnon numero'
                    handlechange = { (e) => setHouseNumber(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <HouseSelect 
                    title = 'Taloyhtiö'
                    name = 'house'
                    value = { house }
                    options = { houses }
                    handlechange = { (e) => setHouse(e.target.children[e.target.selectedIndex].value) }
                    placeholder = { 'Taloyhtiö' }
                />
                <Input 
                    inputtype = 'password'
                    name = 'password'
                    title = 'Salasana'
                    value = { password }
                    placeholder = 'Salasana'
                    handlechange = { (e) => setPassword(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'password'
                    name = 'confirmPassword'
                    title = 'Salasanan vahvistus'
                    value = { confirmPassword }
                    placeholder = 'Salasanan vahvistus'
                    handlechange = { (e) => setConfirmPassword(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Select 
                    title = 'Käyttäjän rooli'
                    name = 'selectedRole'
                    value = { selectedRole }
                    options = { roles }
                    handlechange = { (e) => setSelectedRole(e.target.children[e.target.selectedIndex].value) }
                    placeholder = { 'Käyttäjän rooli' }
                />
                <Wrapper 
                    name = 'selectedHouses'
                    invalidFields = { invalidFields }
                >
                    <h2>Kiinteistökohtaiset käyttöoikeudet</h2>
                    <div style={{ marginBottom: '30px' }}>
                        <Checkbox 
                            key = { 'all_houses' }
                            id = { 'all_houses' }
                            name = { 'Kaikki kiinteistöt' }
                            action = { () =>  setPermissionToAllHouses(!permissionToAllHouses) }
                            checked = {
                                permissionToAllHouses ?
                                    true 
                                : null
                            }
                        />
                    </div>
                    <div className='CheckboxContainer'>
                        { houses && houses.length > 0 &&
                            houses.map(house => {
                                return (
                                    <Checkbox 
                                        key = { house.house_id }
                                        id = { house.house_id}
                                        name = { house.nimi }
                                        action = { () =>  handleSelectHouses(house) }
                                        selected = { selectedHouses }
                                        checked = {
                                            selectedHouses.filter(i => i.house_id === house.house_id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </div>
                </Wrapper>
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default CreateUsers