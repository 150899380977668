import React from 'react';

import './input.scss';

const Input = props => {
	return (
		<div className='InputWrapper'>
			{ props.title ? 
				<strong><label htmlFor={ props.name }>
					{ props.title }
					{ props.required ? <span className="Required">*</span> : null }
				</label></strong>
			: null }
			<input
				className = { props.invalidFields && props.invalidFields.includes(props.name) ? 'InputField Invalid' : 'InputField' }
				id = { props.name }
				name = { props.name}
				type = { props.inputtype }
				value = { props.value }
				onChange = { props.handlechange }
				placeholder = { props.placeholder }
				defaultValue = { props.defaultValue }
			/>
		</div>
	)
}

export default Input