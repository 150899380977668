import React from 'react';

import './checkbox.scss';

const RoleCheckbox = (props) => (
    <div className='Checkbox'>
        <input 
            type = 'checkbox' 
            id = { 'role_' + props.item.id } 
            value = { props.value ? props.value : props.item.id } 
            name = { props.item.name } 
            checked = { props.checked ? props.checked : '' } 
            onChange = { props.action } 
        />
        <label htmlFor={ 'role_' + props.item.id }>{ props.item.name }</label>
    </div>
)

export default RoleCheckbox