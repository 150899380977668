import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Layout from '../../components/UI/layout/layout';
import Table from '../../components/UI/table/table';
import AuthContext from '../../stores/authContext';
import Button from '../../components/formElements/button/button';

function HouseTabsIndex(props) {
    const { user } = useContext(AuthContext)
    const [tabs, setTabs] = useState([])
    const [roles, setRoles] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/admin`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setTabs(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
        }
    }, [user])

    return (
        <Layout>
            <h1>Välilehdet</h1>
            <Button 
                action = { () => props.history.push('/kiinteistot/valilehdet/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            { tabs && tabs.length > 0 &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Käyttäjäroolit</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { tabs.map(tab => {
                            return (
                                <tr key={ tab.id }>
                                    <td>
                                        { tab.name }
                                    </td>
                                    <td>
                                        <ul style={{ margin: '0', padding: '0' }}>
                                            { roles.filter(i => i.tab_id === tab.id).map(role => { return <li key={ role.id }>{ role.name }</li> }) }
                                        </ul>
                                    </td>
                                    <td>
                                        <NavLink to={`/kiinteistot/valilehdet/muokkaa/${tab.id}`}>
                                            Muokkaa
                                        </NavLink>
                                        <NavLink to={`/kiinteistot/valilehdet/poista/${tab.id}`}>
                                            Poista
                                        </NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default HouseTabsIndex