import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';

function DeleteProductCategories(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [category, setCategory] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/products/categories/${props.match.params.id}`).then(res => {
            setCategory(res.data)
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
        })
    }, [props.match.params.id, setMessage])

    function handleFormSubmit(e) {
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_API_PATH}/api/products/categories/${category.id}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Tuotekategorian poistaminen onnistui.', class: 'Success' })
            props.history.push('/tuotteet/kategoriat')
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            { category &&
                <React.Fragment>
                    <h2>Oletko varma, että haluat poistaa tuotekategorian { category.name }?</h2>
                    <Button 
                        action = { () => props.history.push('/tuotteet/kategoriat') }
                        type = 'btn btn-primary'
                        title = 'Takaisin'
                    />
                    <Button 
                        action = { handleFormSubmit }
                        type = 'btn btn-danger'
                        title = 'Poista'
                    />
                </React.Fragment>
            }
        </Layout>
    )
}

export default DeleteProductCategories