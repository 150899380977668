import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import HouseFilesValidation from '../../validation/houseFilesValidation';
import HouseSelect from '../../components/formElements/select/houseSelect';
import Sidebar from '../../components/UI/sidebar/sidebar';
import Wrapper from '../../components/UI/wrapper/wrapper';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import MainContent from '../../components/UI/mainContent/mainContent';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';

function EditHouseFiles(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [tabs, setTabs] = useState([])
    const [selectedTabs, setSelectedTabs] = useState([])
    const [roles, setRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [houses, setHouses] = useState([])
    const [houseID, setHouseID] = useState('')
    const [fileName, setFileName] = useState('')
    const [label, setLabel] = useState('')
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses/${user.token}`).then(res => {
                setHouses(res.data)
            }).catch(() => {
                setMessage({ message: 'Kiinteistöjen hakeminen epäonnistui', class: 'Error' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setCategories(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/admin`, {
				headers: {
					'auth-token': user.token
				}
			}).then(res => {
				setTabs(res.data)
			})

            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-files/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setHouseID(res.data.house_id)
                setFileName(res.data.file_name)
                setLabel(res.data.label)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-files/${props.match.params.id}/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data && res.data.length > 0 && res.data[0].id && setSelectedCategories(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-files/${props.match.params.id}/tabs`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data && res.data.length > 0 && res.data[0].id && setSelectedTabs(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-files/${props.match.params.id}/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data && res.data.length > 0 && res.data[0].id && setSelectedRoles(res.data)
            })
        }
    }, [user, setMessage, props.match.params.id])

    function handleFiles(e) {
        if (e.target.files.length) {
            const fd = new FormData()
            fd.append(e.target.name, e.target.files[0], e.target.files[0].name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/files/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setFileName(res.data)
            })
        }
    }

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleSelectTabs(tab) {
        if (selectedTabs && selectedTabs.filter(i => i.name === tab.name).length > 0) {
            const index = selectedTabs.findIndex(i => i.name === tab.name)
            setSelectedTabs(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedTabs(prevState => [...prevState, tab])
        }
    }

    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = HouseFilesValidation(houseID, fileName)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            house_id: houseID,
            file_name: fileName,
            label: label,
            categories: selectedCategories,
            tabs: selectedTabs,
            roles: selectedRoles
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/house-files/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Tiedoston muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            <div className='GuideForm'>
                <MainContent>
                    { message &&
                        <Message 
                            classes = { message.class }
                            message = { message.message }
                            closeMessage = { () => setMessage(null) }
                        />
                    }
                    <h1>Muokkaa tiedostoa</h1>
                    <form onSubmit={ handleFormValidation }>
                        <input
                            style={{ marginBottom: '15px' }} 
                            type = 'file'
                            name = 'file'
                            placeholder = { fileName }
                            onChange = { handleFiles }
                        />
                        <Input 
                            inputtype = 'text'
                            name = 'label'
                            title = 'Otsikko'
                            value = { label }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setLabel(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        { houses &&
                            <HouseSelect 
                                title = 'Kiinteistöt'
                                name = 'houseID'
                                value = { houseID }
                                options = { houses }
                                handlechange = { (e) => setHouseID(e.target.children[e.target.selectedIndex].value) }
                                placeholder = { 'Valitse kiinteistö' }
                            />
                        }
                        <Button 
                            action = { handleFormValidation }
                            type = 'btn btn-primary'
                            title = 'Tallenna'
                        />
                    </form>
                </MainContent>
                <Sidebar>
                    <Wrapper 
                        name = 'selectedCategories'
                        invalidFields = { invalidFields }
                    >
                        <h2>Kategoriat</h2>
                        { categories &&
                            categories.map(category => {
                                return (
                                    <CategoryCheckbox 
                                        key = { category.id }
                                        category = { category }
                                        action = { () =>  handleSelectCategories(category) }
                                        selected = { selectedCategories }
                                        checked = {
                                            selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </Wrapper>
                    <Wrapper 
                        name = 'selectedTabs'
                        invalidFields = { invalidFields }
                    >
                        <h2>Välilehdet</h2>
                        { tabs &&
                            tabs.map(tab => {
                                return (
                                    <CategoryCheckbox 
                                        key = { tab.id }
                                        category = { tab }
                                        action = { () =>  handleSelectTabs(tab) }
                                        selected = { selectedTabs }
                                        checked = {
                                            selectedTabs.filter(i => i.id === tab.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </Wrapper>
                    <Wrapper
                        name = 'selectedRoles'
                        invalidFields = { invalidFields }
                    >
                        <h2>Käyttäjäryhmät</h2>
                        { roles &&
                            roles.map(role => {
                                return (
                                    <RoleCheckbox 
                                        key = { role.id }
                                        item = { role }
                                        action = { () =>  handleSelectRoles(role) }
                                        checked = {
                                            selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            })
                        }
                    </Wrapper>
                </Sidebar>
            </div>
        </Layout>
    )
}

export default EditHouseFiles