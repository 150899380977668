import { createContext, useState, useEffect, useCallback } from "react";
import TextBlock from "../components/blocks/textBlock/textBlock";
import FileBlock from "../components/blocks/fileBlock/fileBlock";
import ImageBlock from "../components/blocks/imageBlock/imageBlock";
import VideoBlock from "../components/blocks/videoBlock/videoBlock";
import ConditionalBlock from "../components/blocks/conditionalBlock/conditionalBlock";
import usePrevious from "../custom/usePrevious";
import LinkBlock from "../components/blocks/linkBlock/linkBlock";
import SuccessBlock from "../components/blocks/successBlock/successBlock";

const GuideContext = createContext({
    pageBlocks: null,
    pageContent: null,
    removeBlock: () => {},
    moveBlockDown: () => {},
    moveBlockUp: () => {},
    clearState: () => {},
    handleSetPageBlocks: () => {},
    getNextKey: () => {},
    getNextId: () => {},
    drag: () => {},
    dragOver: () => {},
    dragEnd: () => {},
    dragEnter: () => {},
    dragLeave: () => {},
    drop: () => {}
})

export const GuideContextProvider = ({ children }) => {
    const [pageBlocks, setPageBlocks] = useState('')
    const [pageContent, setPageContent] = useState('')
    const [draggedBlockId, setDraggedBlockId] = useState(null)
    const [targetBlockId, setTargetBlockId] = useState(null)
    const prevBlocks = usePrevious(pageBlocks)

    useEffect(() => {
        if (prevBlocks && pageBlocks.length > prevBlocks.length && pageBlocks[pageBlocks.length - 1].props.parent === 0) {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
        }
    }, [pageBlocks, prevBlocks])

    const clearState = useCallback(() => {
        setPageBlocks('')
        setPageContent('')
    }, [])

    const removeBlock = id => {
        const blockIndex = pageBlocks.findIndex(i => parseInt(i.key) === parseInt(id))
        const contentIndex = pageContent.findIndex(i => parseInt(i.id) === parseInt(id))
        setPageBlocks(prevState => [...prevState.slice(0, blockIndex), ...prevState.slice(blockIndex + 1)])
        setPageContent(prevState => [...prevState.slice(0, contentIndex), ...prevState.slice(contentIndex + 1)])
    }

    const moveBlockDown = id => {
        const blockIndex = pageBlocks.findIndex(i => parseInt(i.key) === parseInt(id))
        const block = pageBlocks[blockIndex]
        const contentIndex = pageContent.findIndex(i => parseInt(i.id) === parseInt(id))
        const content = pageContent[contentIndex]

        const blocksCopy = pageBlocks.slice(0)
        let countBlock =  1
        let resultBlock = false
        do {
            if (pageBlocks[blockIndex + countBlock] && pageBlocks[blockIndex + countBlock].props.parent === block.props.parent) {
                blocksCopy.splice(blockIndex, 1)
                blocksCopy.splice(blockIndex + countBlock, 0, block)
                resultBlock = true
            } else if (pageBlocks[blockIndex + countBlock]) {
                countBlock++
            } else {
                resultBlock = true
            }
        } while (!resultBlock)
        
        setPageBlocks(blocksCopy)

        const contentCopy = pageContent.slice(0)
        let countContent =  1
        let resultContent = false
        do {
            if (pageContent[contentIndex + countContent] && pageContent[contentIndex + countContent].parent === content.parent) {
                contentCopy.splice(contentIndex, 1)
                contentCopy.splice(contentIndex + countContent, 0, content)
                resultContent = true
            } else if (pageContent[contentIndex + countContent]) {
                countContent++
            } else {
                resultContent = true
            }
        } while (!resultContent)
        
        setPageContent(contentCopy)
    }

    const moveBlockUp = id => {
        const blockIndex = pageBlocks.findIndex(i => parseInt(i.key) === parseInt(id))
        const block = pageBlocks[blockIndex]
        const contentIndex = pageContent.findIndex(i => parseInt(i.id) === parseInt(id))
        const content = pageContent[contentIndex]

        const blocksCopy = pageBlocks.slice(0)
        let countBlock =  1
        let resultBlock = false
        do {
            if (pageBlocks[blockIndex - countBlock] && pageBlocks[blockIndex - countBlock].props.parent === block.props.parent) {
                blocksCopy.splice(blockIndex, 1)
                blocksCopy.splice(blockIndex - countBlock, 0, block)
                resultBlock = true
            } else if (pageBlocks[blockIndex - countBlock]) {
                countBlock++
            } else {
                resultBlock = true
            }
        } while (!resultBlock)
        
        setPageBlocks(blocksCopy)

        const contentCopy = pageContent.slice(0)
        let countContent =  1
        let resultContent = false
        do {
            if (pageContent[contentIndex - countContent] && pageContent[contentIndex - countContent].parent === content.parent) {
                contentCopy.splice(contentIndex, 1)
                contentCopy.splice(contentIndex - countContent, 0, content)
                resultContent = true
            } else if (pageContent[contentIndex - countContent]) {
                countContent++
            } else {
                resultContent = true
            }
        } while (!resultContent)
        
        setPageContent(contentCopy)
    }

    const handleSetPageBlocks = (block, type) => {
        setPageContent(prevState => [...prevState, { id: parseInt(block.key), value: '', parent: block.props.parent, type: type }])
        setPageBlocks(prevState => [...prevState, block])
    }

    const getNextKey = () => {
        return pageBlocks.length > 0 ? parseInt(Math.max.apply(Math, pageBlocks.map(function(i) { return i.key }))) + 1 : 1
    }

    const getNextId = () => {
        return pageBlocks.length > 0 ? parseInt(Math.max.apply(Math, pageBlocks.map(function(i) { return i.props.id }))) + 1 : 1
    }

    const drag = e => {
        if (!draggedBlockId) {
            setDraggedBlockId(parseInt(e.target.id))
        }
    }

    const dragOver = e => {
        e.preventDefault()
    }

    const dragEnd = () => {
        setDraggedBlockId(null)
    }

    const dragEnter = e => {
        if (e.target.className && e.target.className.length > 0 && e.target.className.includes('OptionBlock')) {
            //e.target.style.border = '3px solid #007bff'
            setTargetBlockId(parseInt(e.target.id))
        } else if (e.target.className === 'BlockContainer') {
            setTargetBlockId(0)
        }
    }

    const dragLeave = e => {
        if (e.target.className === 'OptionBlock') {
            //e.target.style.border = '2px solid #000'
        }
    }
 
    const drop = () => {
        const content = pageContent[pageContent.findIndex(i => i.id === draggedBlockId)]
        // Make sure that blocks parent cannot also be its child
        if (targetBlockId === null || targetBlockId === undefined || pageContent[pageContent.findIndex(i => i.parent === content.id)] || (pageContent[pageContent.findIndex(i => i.parent === content.id)] && pageContent[pageContent.findIndex(i => i.parent === content.id)].id === targetBlockId)) {
            setDraggedBlockId(null)
        } else if (content && content.type) {
            setPageContent(
                prevState => [
                    ...prevState.slice(0, prevState.findIndex(i => i.id === draggedBlockId)), 
                    Object.assign({}, prevState[prevState.findIndex(i => i.id === draggedBlockId)], { parent: targetBlockId }), 
                    ...prevState.slice(prevState.findIndex(i => i.id === draggedBlockId) + 1)
                ]
            )
    
            const blocksCopy = pageBlocks.slice(0)
            const blockIndex = blocksCopy.findIndex(i => i.props.id === draggedBlockId)
            const block = pageBlocks[blockIndex]
    
            blocksCopy.splice(blockIndex, 1)
            if (content.type === 'text-block') {
                blocksCopy.splice(blockIndex, 0, <TextBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { content.value } />)
            } else if (content.type === 'file-block') {
                blocksCopy.splice(blockIndex, 0, <FileBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { JSON.parse(content.value) } />)
            } else if (content.type === 'image-block') {
                blocksCopy.splice(blockIndex, 0, <ImageBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { content.value } />)
            } else if (content.type === 'video-block') {
                blocksCopy.splice(blockIndex, 0, <VideoBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { content.value } />)
            } else if (content.type === 'conditional-block') {
                blocksCopy.splice(blockIndex, 0, <ConditionalBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { content.value } />)
            } else if (content.type === 'link-block') {
                blocksCopy.splice(blockIndex, 0, <LinkBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { JSON.parse(content.value) } />)
            } else if (content.type === 'success-block') {
                blocksCopy.splice(blockIndex, 0, <SuccessBlock key = { block.key } id = { block.props.id } parent = { targetBlockId } initialValue = { content.value } />)
            }
            
            setPageBlocks(blocksCopy)
            setDraggedBlockId(null)
        }
    }

    const context = { pageBlocks, setPageBlocks, pageContent, setPageContent, handleSetPageBlocks, removeBlock, moveBlockDown, moveBlockUp, clearState, getNextKey, getNextId, drag, dragOver, dragEnd, dragEnter, dragLeave, drop }
    
    return (
        <GuideContext.Provider value={context}>
            { children }
        </GuideContext.Provider>
    )
}

export default GuideContext