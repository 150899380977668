import { useContext, useState, useEffect } from 'react';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';
import GuideContext from '../../stores/guideContext';
import Select from '../../components/formElements/select/select';
import Pager from '../../components/pager/pager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GuidesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const { clearState } = useContext(GuideContext)
    const [guides, setGuides] = useState([])
    const [filteredGuides, setFilteredGuides] = useState([])
    const [selectedGuides, setSelectedGuides] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Julkaise' },
        { id: 2, name: 'Poista Julkaisu' },
        { id: 3, name: 'Poista Ohje' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [parentCategories, setParentCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [guideCategories, setGuideCategories] = useState('')
    const [selectedParentCategory, setSelectedParentCategory] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState('')
    const [guideRoles, setGuideRoles] = useState([])
    const [filter, setFilter] = useState('')
    const [orderByName, setOrderByName] = useState('asc')
    const [orderByPublished, setOrderByPublished] = useState('desc')
    const [orderByCreated, setOrderByCreated] = useState('asc')
    const [orderByUpdated, setOrderByUpdated] = useState('asc')
    const [orderByWeight, setOrderByWeight] = useState('asc')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getGuides() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/guides`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setGuides(res.data)
            setFilteredGuides(res.data)
        })
    }

    useEffect(() => {
        if (user && user.token) {
            getGuides()
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/guides/guide_categories`).then(res => {
                setGuideCategories(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/guides/guide_roles`).then(res => {
                setGuideRoles(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                console.log(res.data)
                setRoles(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setParentCategories(res.data)
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        guides.map(guide => {
            if (((!filter || guide.name.toLowerCase().includes(filter.toLowerCase())) && (!selectedCategory || guideCategories.filter(i => i.guide_id === guide.id && i.category_id === selectedCategory).length > 0)) && (!selectedRole || guideRoles.filter(i => i.guide_id === guide.id && i.role_id === selectedRole).length > 0)) {
                return filtered.push(guide)
            } else {
                return null
            }
        })
        setFilteredGuides(filtered)


    }, [filter, selectedCategory, guideCategories, guides, selectedRole, guideRoles])

    useEffect(() => {
        setFirstItem(0)
    }, [filter, selectedCategory])

    function addNewGuide() {
        clearState()
        props.history.push('/ohjeet/lisaa')
    }

    function orderGuidesByName() {
        let guidesCopy = guides.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.name < b.name) {
                return orderByName === 'asc' ? - 1 : 1
            } else if (a.name > b.name) {
                return orderByName === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByName === 'asc' ? setOrderByName('desc') : setOrderByName('asc')
        
        setGuides(guidesCopy)
    }

    function orderGuidesByPublished() {
        let guidesCopy = guides.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.published < b.published) {
                return orderByPublished === 'asc' ? - 1 : 1
            } else if (a.published > b.published) {
                return orderByPublished === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByPublished === 'asc' ? setOrderByPublished('desc') : setOrderByPublished('asc')
        
        setGuides(guidesCopy)
    }

    function orderGuidesByCreated() {
        let guidesCopy = guides.splice(0)

        guidesCopy.sort((a, b) => {
            if (new Date(a.created_at) < new Date(b.created_at)) {
                return orderByCreated === 'asc' ? - 1 : 1
            } else if (new Date(a.created_at) > new Date(b.created_at)) {
                return orderByCreated === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByCreated === 'asc' ? setOrderByCreated('desc') : setOrderByCreated('asc')
        
        setGuides(guidesCopy)
    }

    function orderGuidesByUpdated() {
        let guidesCopy = guides.splice(0)

        guidesCopy.sort((a, b) => {
            if (new Date(a.updated_at) < new Date(b.updated_at)) {
                return orderByUpdated === 'asc' ? - 1 : 1
            } else if (new Date(a.updated_at) > new Date(b.updated_at)) {
                return orderByUpdated === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByUpdated === 'asc' ? setOrderByUpdated('desc') : setOrderByUpdated('asc')
        
        setGuides(guidesCopy)
    }

    function orderGuidesByWeight() {
        let guidesCopy = guides.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.weight < b.weight) {
                return orderByWeight === 'asc' ? - 1 : 1
            } else if (a.weight > b.weight) {
                return orderByWeight === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByWeight === 'asc' ? setOrderByWeight('desc') : setOrderByWeight('asc')
        
        setGuides(guidesCopy)
    }

    function handleParentCategory(value) {
        setSelectedCategory(value)
        setSelectedParentCategory(value)

        if (value !== 0) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/categories/${value}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSubCategories(res.data)
            })
        } else {
            setSubCategories([])
        }
    }

    function handleSubcategory(value) {
        if (value !== 0) {
            setSelectedCategory(value)
        } else {
            setSelectedCategory(selectedParentCategory)
        }
    }

    function handleGuideSelection(id) {
        if (selectedGuides.includes(id)) {
            setSelectedGuides(selectedGuides.filter(i => i !== id))
        } else {
            setSelectedGuides([...selectedGuides, id])
        }
    }

    function selectAllGuides() {
        if (document.getElementById('SelectAllGuides').checked) {
            let guideArray = []
            document.querySelectorAll('.SelectGuide').forEach(checkbox => {
                checkbox.checked = true
                guideArray.push(parseInt(checkbox.value))
            })
            setSelectedGuides(guideArray)
        } else {
            document.querySelectorAll('.SelectGuide').forEach(checkbox => {
                checkbox.checked = false
                setSelectedGuides([])
            })
        }
    }

    function unSelectAllGuides() {
        document.getElementById('SelectAllGuides').checked = false
        document.querySelectorAll('.SelectGuide').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedGuides([])
    }

    function handleMassEdit() {
        if (selectedGuides.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse ohjeet' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/guides/publish`, {
                guides: selectedGuides
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeet julkaistu' })
                getGuides()
                unSelectAllGuides()
            })
        } else if (massEdit === 2) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/guides/unpublish`, {
                guides: selectedGuides
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeiden tila muutettu julkaisemattomaksi' })
                getGuides()
                unSelectAllGuides()
            })
        } else if (massEdit === 3) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/guides/delete`, {
                guides: selectedGuides
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeet poistettu' })
                getGuides()
                unSelectAllGuides()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Ohjeet</h1>
            <Button 
                action = { addNewGuide }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <Button 
                action = { () => props.history.push('/ohjeet/kategoriat') }
                type = 'btn btn-primary'
                title = 'Kategoriat'
            />
            <Button 
                action = { () => props.history.push('/ohjeet/oireet') }
                type = 'btn btn-primary'
                title = 'Oireet'
            />
            <Button 
                action = { () => props.history.push('/avainsanat') }
                type = 'btn btn-primary'
                title = 'Avainsanat'
            />
            { parentCategories && parentCategories.length > 0 &&
                <div className='GuideFilters'>
                    <Select 
                        name = 'massEdit'
                        value = { massEdit }
                        options = { massEditOptions }
                        handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = 'Massamuokkaus'
                    />
                    <Button
                        action = { () => handleMassEdit() }
                        type = 'btn btn-success btn-mass-edit'
                        title = 'Suorita'
                    />
                    <Select 
                        name = 'selectedParentCategory'
                        value = { selectedParentCategory }
                        options = { parentCategories }
                        handlechange = { (e) => handleParentCategory(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Kategoria' }
                    />
                    { selectedCategory && subCategories && subCategories.length > 0 ?
                        <Select 
                            name = 'selectedCategory'
                            value = { selectedCategory }
                            options = { subCategories }
                            handlechange = { (e) => handleSubcategory(parseInt(e.target.children[e.target.selectedIndex].value)) }
                            placeholder = { 'Alakategoria' }
                        />
                    :
                        null
                    }
                    <Select 
                        name = 'selectedRole'
                        value = { selectedRole }
                        options = { roles }
                        handlechange = { (e) => setSelectedRole(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Käyttäjäryhmät' }
                    />
                    <input 
                        style = {{ float: 'right', padding: '10px', marginTop: '15px' }} 
                        value = { filter } placeholder='Hae...' 
                        onChange = { (e) => setFilter(e.target.value) } 
                    />
                </div>
            }
            { guides &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllGuides" onChange={() => selectAllGuides()} type='checkbox' /></th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByName() }>
                                Otsikko { orderByName === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByPublished() }>
                                Julkaistu { orderByPublished === 'desc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByCreated() }>
                                Lisätty { orderByCreated === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByUpdated() }>
                                Päivitetty { orderByUpdated === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByWeight() }>
                                Paino { orderByWeight === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredGuides.slice(0).splice(firstItem, itemsToShow).map(guide => {
                            return (
                                <tr key={ guide.id }>
                                    <td><input className="SelectGuide" onChange={ () => handleGuideSelection(guide.id)
                                    } type='checkbox' value={ guide.id } checked={ selectedGuides.includes(guide.id) ? true : false } /></td>
                                    <td>{ guide.name }</td>
                                    <td>{ guide.published === 1 ? 'Kyllä' : 'Ei' }</td>
                                    <td>{ `${new Date(guide.created_at).getDate()}.${new Date(guide.created_at).getMonth() + 1}.${new Date(guide.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(guide.updated_at).getDate()}.${new Date(guide.updated_at).getMonth() + 1}.${new Date(guide.updated_at).getFullYear()}` }</td>
                                    <td>{ guide.weight }</td>
                                    <td>
                                        <NavLink to={`/ohjeet/muokkaa/${guide.machine_name}`}>Muokkaa</NavLink>
                                        <NavLink to={`/ohjeet/${guide.machine_name}`}>Näytä</NavLink>
                                        <NavLink to={`/ohjeet/poista/${guide.machine_name}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredGuides.length }
                filter = { filter }
                filter2 = { selectedCategory }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default GuidesIndex