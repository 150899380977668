import React, { useContext, useState, useEffect } from 'react';
import './reportLinkBlock.scss';
import GuideContext from '../../../stores/guideContext';
import Input from '../../formElements/input/input';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ReportLinkBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const [label, setLabel] = useState('Tee vikailmoitus')
    const [draggable, setDraggable] = useState(false)
    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')

    useEffect(() => {
        if (props.initialValue) {
            setLabel(props.initialValue.label)
        }
    }, [props.initialValue])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    useEffect(() => {
        if (label) {
            setPageContent(
                prevState => [
                    ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                    Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"label":"${label}"}`, type: 'report-link-block', parent: props.parent }), 
                    ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
                ]
            )
        }
    }, [label, props.id, props.parent, setPageContent])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'report-link-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    return (
        <div 
            className = 'LinkBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { 'Vikailmoitus-linkki ' + count }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            <button target='_blank' rel='noreferrer'>{ label }</button>
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'label'
                            title = 'Otsikko'
                            value = { label }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setLabel(e.target.value)}
                        />
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default ReportLinkBlock