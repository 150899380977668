import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';

function ShowPolicies(props) {
	const [guide, setGuide] = useState('')
	const [tags, setTags] = useState([])
    const [content, setContent] = useState('')
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setGuide(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/content`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setContent(res.data.sort((a, b) => a.position > b.position ? 1 : -1))
            })
            
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/tags`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setTags(res.data)
            })
        }
    }, [props.match.params.machine_name, user])

    function handleContent(item) {
		if (item.type === 'image-block') {
			return (
				<div
					className = 'ContentBlock' 
					key = { item.position }
				>
                    <img src={ `${process.env.REACT_APP_API_PATH}/images/${JSON.parse(item.value).path}` } alt={ JSON.parse(item.value).altText } />
					<figcaption>{ JSON.parse(item.value).caption }</figcaption>
				</div>
			)
		} else if (item.type === 'file-block') {
			return (
				<div
					className = 'ContentBlock'  
					key = { item.position }
				>
					<a href={ `${process.env.REACT_APP_API_PATH}/uploads/${JSON.parse(item.value).fileName}` } target='_blank' rel='noreferrer'>
						{ JSON.parse(item.value).label }
					</a>
				</div>
			)
		} else {
			return (
				<div 
                    className = 'ContentBlock'  
					key = { item.position } 
					dangerouslySetInnerHTML = {{ __html: item.value }}>
				</div>
			)
		}
	}

    return (
        <Layout>
            { guide &&
                <div className='Guide'>
                    <h1>{ guide.name }</h1>
                    { content &&
                        content.map(item => {
                            return handleContent(item)
                        })
					}
					{ tags && tags.length > 0 && tags[0].id &&
                        <React.Fragment>
                            <h3>Avainsanat</h3>
                            { tags.map(tag => {
                                return (
                                    <NavLink style={{ marginRight: '5px' }} key={ tag.id } to='#'>
                                        { tag.name }
                                    </NavLink>
                                )
                            }) }
                        </React.Fragment>
                    }
                </div>
            }
        </Layout>
    )
}

export default ShowPolicies