import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';

import Input from '../../components/formElements/input/input';
import ImageInput from '../../components/formElements/imageInput/imageInput';
import ImagePreview from '../../components/formElements/imagePreview/imagePreview';
import Button from '../../components/formElements/button/button';
import AuthContext from '../../stores/authContext';
import MainContent from '../../components/UI/mainContent/mainContent';
import Sidebar from '../../components/UI/sidebar/sidebar';
import Layout from '../../components/UI/layout/layout';
import Message from '../../components/message/message';
import Tag from '../../components/tag/tag';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import Textarea from '../../components/formElements/textarea/textarea';
import TextBlock from '../../components/blocks/textBlock/textBlock';
import ImageBlock from '../../components/blocks/imageBlock/imageBlock';
import GuideContext from '../../stores/guideContext';
import FileBlock from '../../components/blocks/fileBlock/fileBlock';
import Dropdown from '../../components/UI/dropdown/dropdown';
import GuideValidation from '../../validation/guideValidation';
import Wrapper from '../../components/UI/wrapper/wrapper';
import VideoBlock from '../../components/blocks/videoBlock/videoBlock';
import ConditionalBlock from '../../components/blocks/conditionalBlock/conditionalBlock';
import Spinner from '../../components/UI/spinner/spinner';
import GuideTypeCheckbox from '../../components/formElements/checkbox/guideTypeCheckbox';
import SidebarLeft from '../../components/UI/sidebarLeft/sidebarLeft';
import SymptomCheckbox from '../../components/formElements/checkbox/symptomCheckbox';
import LinkBlock from '../../components/blocks/linkBlock/linkBlock';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';
import ReportLinkBlock from '../../components/blocks/reportLinkBlock/reportLinkBlock';
import InputInteger from '../../components/formElements/input/inputInteger';

function CreateGuides(props) {
    const [guides, setGuides] = useState([])
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [weight, setWeight] = useState(0)
    const [description, setDescription] = useState('')
    const [published, setPublished] = useState(0)
    const [image, setImage] = useState('')
    const [imageID, setImageID] = useState('')
    const [imageName, setImageName] = useState('')
    const [categories, setCategories] = useState('')
    const [symptoms, setSymptoms] = useState([])
    const [selectedSymptoms, setSelectedSymptoms] = useState([])
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState('')
    const [selectedTags, setSelectedTags] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [guideTypes, setGuideTypes] = useState('')
    const [selectedGuideTypes, setSelectedGuideTypes] = useState([])
    const [roles, setRoles] = useState('')
    const [selectedRoles, setSelectedRoles] = useState('')
    const [invalidFields, setInvalidFields] = useState([])
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const { user, message, setMessage } = useContext(AuthContext)
    const { pageBlocks, setPageBlocks, pageContent, setPageContent, handleSetPageBlocks, getNextKey, getNextId, dragEnter, dragOver, dragLeave, drop, clearState } = useContext(GuideContext)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        clearState()
    }, [clearState])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/guides`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setGuides(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                const sortedByParent = res.data.sort((a, b) => a.parent_category > b.parent_category ? 1 : -1)
                const sortedCategories = []
                sortedByParent.map(category => {
                    if (!category.parent_category) {
                        category.level = 1
                        return sortedCategories.push(category)
                    } else {
                        category.level = 2   
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)      
                    }
                })
                setCategories(sortedCategories.reverse())
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/guide-types`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setGuideTypes(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
        }
    }, [user, setPageBlocks, setPageContent])

    useEffect(() => {
        setMachineName(name.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase())
    }, [name, specialChars])

    useEffect(() => {
        if (image && user && user.token) {
            const fd = new FormData()
            fd.append('image', image, image.name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setMessage({ message: res.data.error, class: 'Error' })
                    window.scrollTo({ top: 0, behavior: "smooth" })
                } else {
                    setImageID(res.data.id)
                    setImageName(res.data.name)
                }
            })
        }
    }, [image, user, setMessage])

    useEffect(() => {
        if (selectedCategories && selectedCategories.length > 0 && user && user.token) {
            let selectedIds = []
            selectedCategories.filter(i => i.level === 2).map(item => {
                if (selectedCategories.filter(i => i.id === item.parent_category).length === 0) {
                    return setSelectedCategories(prevState => [...prevState.slice(0, selectedCategories.indexOf(item)), ...prevState.slice(selectedCategories.indexOf(item) + 1)])
                } else {
                    return null
                }
            })

            selectedCategories.map(item => {
                return selectedIds.push(item.id)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/symptoms/selected/${selectedIds}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                let symptomArray = []
                res.data.map(symptom => {
                    if (symptomArray.filter(i => i.id === symptom.id).length === 0) {
                        return symptomArray.push(symptom)
                    } else {
                        return null
                    }
                })

                setSymptoms(symptomArray)
            })
        } else {
            setSymptoms([])
        }
        
    }, [selectedCategories, user])

    useEffect(() => {
        if (tag.length > 1) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/tags/${tag}`).then(res => {
                setTags(res.data)
            })
        } else {
            setTags('')
        }
    }, [tag])

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleSelectTags(tag) {
        if (selectedTags && selectedTags.filter(i => i.name === tag.name).length > 0) {
            const index = selectedTags.findIndex(i => i.name === tag.name)
            setSelectedTags(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedTags(prevState => [...prevState, tag])
        }
    }

    function handleSelectGuideTypes(guideType) {
        if (selectedGuideTypes && selectedGuideTypes.filter(i => i.name === guideType.name).length > 0) {
            const index = selectedGuideTypes.findIndex(i => i.name === guideType.name)
            setSelectedGuideTypes(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedGuideTypes(prevState => [...prevState, guideType])
        }
    }

    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function handleSelectSymptoms(symptom) {
        if (selectedSymptoms && selectedSymptoms.filter(i => i.name === symptom.name).length > 0) {
            const index = selectedSymptoms.findIndex(i => i.name === symptom.name)
            setSelectedSymptoms(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedSymptoms(prevState => [...prevState, symptom])
        }
    }

    function addTagHandler(e) {
        e.preventDefault()
        if (tags && tags.filter(i => i.name === tag).length > 0) {
            handleSelectTags(tags[tags.findIndex(i => i.name === tag)])
        } else {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/tags`, {
                name: tag
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSelectedTags(prevState => [...prevState, res.data])
            })
            .catch(() => {
                setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            })
        }
    }

    function handleFormValidation(e, publish) {
        e.preventDefault()
        const validation = GuideValidation(name, machineName, weight, description, selectedCategories, selectedGuideTypes, guides)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit(publish)
        }
    }

    function handleFormSubmit(publish) {
        if (publish) {
            setPublished(1)
        } else {
            setPublished(publish)
        }

        let contentSortedByParent = []
        let contentReArrangeOptions = []
        if (pageContent) {
            pageContent.map(content => {
                if (contentSortedByParent.findIndex(i => i.id === content.parent) !== -1) {
                    return contentSortedByParent.splice(contentSortedByParent.findIndex(i => i.id === content.parent) + contentSortedByParent.filter(i => i.parent === content.parent).length + 1, 0, content)
                } else {
                    return contentSortedByParent.push(content)
                }
            })

            contentSortedByParent.map(content => {
                if (contentReArrangeOptions.findIndex(i => i.id === content.parent) !== -1 && content.type === 'option-block') {
                    return contentReArrangeOptions.splice(contentReArrangeOptions.findIndex(i => i.id === content.parent) + contentReArrangeOptions.filter(i => i.parent === content.parent).length + 1, 0, content)
                } else {
                    return contentReArrangeOptions.push(content)
                }
            })

            let count = 0
            contentReArrangeOptions.map(content => {
                count++
                return (
                    content.position = count
                )
            })
        }

        const data = {
            name: name,
            machine_name: machineName,
            weight: weight,
            description: description,
            image_id: imageID ? imageID : 1,
            categories: selectedCategories,
            guide_symptoms: selectedSymptoms,
            tags: selectedTags,
            guide_types: selectedGuideTypes,
            guide_roles: selectedRoles,
            published: publish,
            guide_content: contentReArrangeOptions
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/guides`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            clearState()
            setMessage({ message: 'Ohjeen lisääminen onnistui.', class: 'Success' })
            props.history.push('/ohjeet')
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout classes='Wide'>
            {!loading?
                <div className='GuideForm'>
                    <SidebarLeft>
                        <div className='AddBlocksWrapper'>
                            <h2>Lisää sisältölohkoja</h2>
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <TextBlock 
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                    />, 'text-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tekstialue'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <ImageBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'image-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Kuva'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <FileBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'file-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tiedosto'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <VideoBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'video-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Video'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <ConditionalBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                        level = { 1 }
                                    />, 'conditional-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Ehdollinen'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <LinkBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                        level = { 1 }
                                    />, 'link-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Linkki'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <ReportLinkBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                        level = { 1 }
                                    />, 'report-link-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Vikailmoitus'
                            />
                        </div>
                    </SidebarLeft>
                    <MainContent classes='Narrow'>
                        { message &&
                            <Message 
                                classes = { message.class }
                                message = { message.message }
                                closeMessage = { () => setMessage(null) }
                            />
                        }
                        <h1>Lisää ohje</h1>
                        <Input 
                            inputtype = 'text'
                            name = 'name'
                            title = 'Otsikko'
                            value = { name }
                            placeholder = 'Ohjeen otsikko'
                            handlechange = { (e) => setName(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        <Input 
                            inputtype = 'text'
                            name = 'machineName'
                            title = 'Koneluettava otsikko'
                            value = { machineName }
                            placeholder = 'Ohjeen otsikko'
                            handlechange = { 
                                (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                            }
                            invalidFields = { invalidFields }
                        />
                        <ImageInput
                            name = 'image'
                            title = 'Kuva'
                            handlechange = { (e) => setImage(e.target.files[0]) }
                            invalidFields = { invalidFields }
                        />
                        { imageID && 
                            <ImagePreview 
                                image = { `${process.env.REACT_APP_API_PATH}/images/${imageName}` }
                                removeImage = { () => setImageID(null) }
                            />
                        }
                        <InputInteger 
                            inputtype = 'number'
                            name = 'weight'
                            title = 'Paino'
                            value = { weight }
                            placeholder = 'Paino'
                            handlechange = { (e) => setWeight(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        <Textarea 
                            name = 'description'
                            value = { description }
                            rows = { 5 }
                            title = 'Lyhyt kuvaus'
                            handlechange = { (e) => setDescription(e.target.value) }
                            placeholder = 'Lyhyt kuvaus ohjeesta'
                            invalidFields = { invalidFields }
                        />
                        { 
                            <div 
                                style = {{ padding: '30px 0' }}
                                className = 'BlockContainer'
                                onDragOver = { (e) => dragOver(e) }
                                onDrop = { () => drop() }
                                onDragEnter = { (e) => dragEnter(e) }
                                onDragLeave = { (e) => dragLeave(e) }
                            >
                                { pageBlocks && pageBlocks.filter(i => i.props.parent === 0) }
                            </div> 
                        }
                        <Button 
                            action = { (e) => handleFormValidation(e, published) }
                            type = 'btn btn-primary'
                            title = 'Tallenna'
                        />
                        <Button 
                            action = { (e) => handleFormValidation(e, 1) }
                            type = 'btn btn-primary'
                            title = 'Julkaise'
                        />    
                    </MainContent>
                    <Sidebar>
                        <Wrapper 
                            name = 'selectedCategories'
                            invalidFields = { invalidFields }
                        >
                            <h2>Kategoriat</h2>
                            { categories &&
                                categories.map(category => {
                                    return (
                                        <CategoryCheckbox 
                                            key = { category.id }
                                            category = { category }
                                            action = { () =>  handleSelectCategories(category) }
                                            selected = { selectedCategories }
                                            checked = {
                                                selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                                    true 
                                                : null
                                            }
                                        />
                                    )
                                }) 
                            }
                        </Wrapper>
                        <Wrapper 
                            name = 'selectedSymptoms'
                            invalidFields = { invalidFields }
                        >
                            { symptoms && symptoms.length > 0 &&
                                <React.Fragment>
                                    <h2>Oireet</h2>
                                    { symptoms.map(symptom => {
                                        return (
                                            <SymptomCheckbox 
                                                key = { symptom.id }
                                                item = { symptom }
                                                action = { () =>  handleSelectSymptoms(symptom) }
                                                selected = { selectedSymptoms }
                                                checked = {
                                                    selectedSymptoms.filter(i => i.id === symptom.id).length > 0 ?
                                                        true 
                                                    : null
                                                }
                                            />
                                        )
                                    }) }
                                </React.Fragment>
                            }
                        </Wrapper>
                        <Wrapper>
                            <h2>Avainsanat</h2>
                            <form autoComplete='off' onSubmit={ (e) => addTagHandler(e) }>
                                <Input 
                                    inputtype = 'text'
                                    name = 'tag'
                                    value = { tag }
                                    placeholder = 'Avainsana'
                                    handlechange = { (e) => setTag(e.target.value) }
                                />
                            </form>
                            { tags && tags.length > 0 &&
                                <Dropdown>
                                    {tags.map(tag => {
                                        return (
                                            <Tag 
                                                key = { tag.name }
                                                tag = { tag }
                                                action = { () => handleSelectTags(tag) }
                                            />
                                        )
                                    })}
                                </Dropdown>
                            }
                            <h3>Valitut avainsanat</h3>
                            { selectedTags &&
                                selectedTags.map(tag => {
                                    return (
                                        <Tag 
                                            key = { tag.name }
                                            tag = { tag }
                                            action = { () => handleSelectTags(tag) }
                                        />
                                    )                        
                                })
                            }
                        </Wrapper>
                        <Wrapper
                            name = 'selectedGuideTypes'
                            invalidFields = { invalidFields }
                        >
                            <h2>Ohjeen näkyvyys</h2>
                            { guideTypes &&
                                guideTypes.map(guideType => {
                                    return (
                                        <GuideTypeCheckbox 
                                            key = { guideType.id }
                                            item = { guideType }
                                            action = { () =>  handleSelectGuideTypes(guideType) }
                                            checked = {
                                                selectedGuideTypes.filter(i => i.id === guideType.id).length > 0 ?
                                                    true 
                                                : null
                                            }
                                        />
                                    )
                                })
                            }
                        </Wrapper>
                        <Wrapper
                            name = 'selectedRoles'
                            invalidFields = { invalidFields }
                        >
                            <h2>Käyttäjäryhmät</h2>
                            { roles &&
                                roles.map(role => {
                                    return (
                                        <RoleCheckbox 
                                            key = { role.id }
                                            item = { role }
                                            action = { () =>  handleSelectRoles(role) }
                                            checked = {
                                                selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                                    true 
                                                : null
                                            }
                                        />
                                    )
                                })
                            }
                        </Wrapper>
                    </Sidebar>
                </div>
            :
                <Spinner />
            }
        </Layout>
    )
}

export default CreateGuides