import React from 'react';

import './dropdown.scss';

const Dropdown = (props) => (
    <div className='Dropdown'>
        { props.children }
    </div>
)

export default Dropdown