import React from 'react';

import './imageInput.scss'

const ImageInput = props => {
	return (
		<div className='ImageInputWrapper'>
			<strong><label htmlFor={ props.name }>
				{ props.title }
			</label></strong>
			<input
				ref = { props.innerRef }
				className = { props.invalidFields && props.invalidFields.includes('imageID') ? 'ImageInput Invalid' : 'ImageInput' }
				name = { props.name }
                type = 'file'
                accept = 'image/*' 
				onChange = { props.handlechange }
			/>
		</div>
	)
}

export default ImageInput