import { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import HouseSelect from '../../components/formElements/select/houseSelect';
import MainContent from '../../components/UI/mainContent/mainContent';
import Sidebar from '../../components/UI/sidebar/sidebar';
import Wrapper from '../../components/UI/wrapper/wrapper';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import HouseImagesValidation from '../../validation/houseImagesValidation';
import ImageInput from '../../components/formElements/imageInput/imageInput';
import ImagePreview from '../../components/formElements/imagePreview/imagePreview';
import Textarea from '../../components/formElements/textarea/textarea';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';

function CreateHouseImages() {
    const { message, setMessage, user } = useContext(AuthContext)
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [tabs, setTabs] = useState([])
    const [selectedTabs, setSelectedTabs] = useState([])
    const [roles, setRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [houses, setHouses] = useState([])
    const [houseID, setHouseID] = useState('')
    const [image, setImage] = useState('')
    const [imageID, setImageID] = useState('')
    const [imageName, setImageName] = useState('')
    const [caption, setCaption] = useState('')
    const [invalidFields, setInvalidFields] = useState([])
    const inputRef = useRef(null)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses/${user.token}`).then(res => {
                setHouses(res.data)
            }).catch(() => {
                setMessage({ message: 'Kiinteistöjen hakeminen epäonnistui', class: 'Error' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setCategories(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/admin`, {
				headers: {
					'auth-token': user.token
				}
			}).then(res => {
				setTabs(res.data)
			})

            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
        }
    }, [user, setMessage])

    function clearState() {
        inputRef.current.value = ''
        setHouseID('')
        setImage('')
        setImageID('')
        setImageName('')
        setCaption('')
        setSelectedCategories([])
        setSelectedTabs([])
        setSelectedRoles([])
        setInvalidFields([])
    }

    useEffect(() => {
        if (image && user && user.token) {
            const fd = new FormData()
            fd.append('image', image, image.name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setMessage({ message: res.data.error, class: 'Error' })
                    window.scrollTo({ top: 0, behavior: "smooth" })
                } else {
                    setImageID(res.data.id)
                    setImageName(res.data.name)
                }
            })
        }
    }, [image, user, setMessage])

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleSelectTabs(tab) {
        if (selectedTabs && selectedTabs.filter(i => i.name === tab.name).length > 0) {
            const index = selectedTabs.findIndex(i => i.name === tab.name)
            setSelectedTabs(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedTabs(prevState => [...prevState, tab])
        }
    }

    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = HouseImagesValidation(houseID, imageID)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            house_id: houseID,
            image_id: imageID,
            caption: caption,
            categories: selectedCategories,
            tabs: selectedTabs,
            roles: selectedRoles
        }

        axios.post(`${process.env.REACT_APP_API_PATH}/api/house-images`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            clearState()
            setMessage({ message: 'Kuvan lisääminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            <div className='GuideForm'>
                <MainContent>
                    { message &&
                        <Message 
                            classes = { message.class }
                            message = { message.message }
                            closeMessage = { () => setMessage(null) }
                        />
                    }
                    <h1>Lisää kuva</h1>
                    <form onSubmit={ handleFormValidation }>
                        <ImageInput
                            innerRef = { inputRef }
                            name = 'image'
                            title = 'Kuva'
                            handlechange = { (e) => setImage(e.target.files[0]) }
                            invalidFields = { invalidFields }
                        />
                        { imageID && 
                            <ImagePreview 
                                image = { `${process.env.REACT_APP_API_PATH}/images/${imageName}` }
                                removeImage = { () => setImageID(null) }
                            />
                        }
                        <Textarea 
                            name = 'caption'
                            value = { caption }
                            rows = { 5 }
                            title = 'Kuvateksti'
                            handlechange = { (e) => setCaption(e.target.value) }
                            placeholder = 'Kuvateksti'
                        />
                        { houses &&
                            <HouseSelect 
                                title = 'Kiinteistöt'
                                name = 'houseID'
                                value = { houseID }
                                options = { houses }
                                handlechange = { (e) => setHouseID(e.target.children[e.target.selectedIndex].value) }
                                placeholder = { 'Valitse kiinteistö' }
                            />
                        }
                        <Button 
                            action = { handleFormValidation }
                            type = 'btn btn-primary'
                            title = 'Tallenna'
                        />
                    </form>
                </MainContent>
                <Sidebar>
                    <Wrapper 
                        name = 'selectedCategories'
                        invalidFields = { invalidFields }
                    >
                        <h2>Kategoriat</h2>
                        { categories &&
                            categories.map(category => {
                                return (
                                    <CategoryCheckbox 
                                        key = { category.id }
                                        category = { category }
                                        action = { () =>  handleSelectCategories(category) }
                                        selected = { selectedCategories }
                                        checked = {
                                            selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </Wrapper>
                    <Wrapper 
                        name = 'selectedTabs'
                        invalidFields = { invalidFields }
                    >
                        <h2>Välilehdet</h2>
                        { tabs &&
                            tabs.map(tab => {
                                return (
                                    <CategoryCheckbox 
                                        key = { tab.id }
                                        category = { tab }
                                        action = { () =>  handleSelectTabs(tab) }
                                        selected = { selectedTabs }
                                        checked = {
                                            selectedTabs.filter(i => i.id === tab.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </Wrapper>
                    <Wrapper
                        name = 'selectedRoles'
                        invalidFields = { invalidFields }
                    >
                        <h2>Käyttäjäryhmät</h2>
                        { roles &&
                            roles.map(role => {
                                return (
                                    <RoleCheckbox 
                                        key = { role.id }
                                        item = { role }
                                        action = { () =>  handleSelectRoles(role) }
                                        checked = {
                                            selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            })
                        }
                    </Wrapper>
                </Sidebar>
            </div>
        </Layout>
    )
}

export default CreateHouseImages