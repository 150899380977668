const UserEditValidation = (name, email, password, confirmPassword, selectedRole) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Nimi on pakollinen kenttä'
        },
        email: {
            condition: email,
            message: 'Valitse kelvollinen sähköpostiosoite'
        },
        password: {
            condition: !password || password.length > 5,
            message: 'Valitse vähintään 6 merkkiä pitkä salasana'
        },
        confirmPassword: {
            condition: confirmPassword === password,
            message: 'Salasanan vahvistus ei täsmää'
        },
        selectedRole: {
            condition: selectedRole,
            message: 'Valitse käyttäjän rooli'
        }
    })

    return validation
}

export default UserEditValidation