import { useContext, useState, useEffect } from 'react';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';

function RolesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [roles, setRoles] = useState('')

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
        }
    }, [user])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Käyttäjäryhmät</h1>
            <Button 
                action = { () => props.history.push('/kayttajaryhmat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            { roles &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { roles.map(role => {
                            return (
                                <tr key={ role.id }>
                                    <td>{ role.name }</td>
                                    <td>{ `${new Date(role.created_at).getDate()}.${new Date(role.created_at).getMonth() + 1}.${new Date(role.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(role.updated_at).getDate()}.${new Date(role.updated_at).getMonth() + 1}.${new Date(role.updated_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/kayttajaryhmat/muokkaa/${role.id}`}>Muokkaa</NavLink>
                                        <NavLink to={`/kayttajaryhmat/poista/${role.id}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default RolesIndex