import React, { useContext } from 'react';
import axios from 'axios';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';

function DeleteHouseVideos(props) {
    const { user, message, setMessage } = useContext(AuthContext)

    function handleFormSubmit(e) {
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_API_PATH}/api/house-videos/${props.match.params.id}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Videon poistaminen onnistui.', class: 'Success' })
            props.history.push('/kiinteistot/videot')
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h2>Oletko varma, että haluat poistaa videon?</h2>
            <Button 
                action = { () => props.history.push('/kiinteistot/videot') }
                type = 'btn btn-primary'
                title = 'Takaisin'
            />
            <Button 
                action = { handleFormSubmit }
                type = 'btn btn-danger'
                title = 'Poista'
            />
        </Layout>
    )
}

export default DeleteHouseVideos