import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Textarea from '../../components/formElements/textarea/textarea';

function EditTexts(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [texts, setTexts] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/texts/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setTexts(res.data)
            })
        }
    }, [user, props.match.params.id])

    function handleTexts(id, value) {
        let textsCopy = texts.slice(0)
        textsCopy[textsCopy.findIndex(i => i.id === id)].value = value
        setTexts(textsCopy)
    }

    function handleFormSubmit(e) {
        e.preventDefault()
        let count = 0
        texts.map(text => {
            const data = {
                value: text.value
            }
            
            return (
                axios.patch(`${process.env.REACT_APP_API_PATH}/api/texts/${text.id}`, data, {
                    headers: {
                        'auth-token': user.token
                    }
                }).then(() => {
                    if (count === texts.length - 1) {
                        setMessage({ message: 'Tekstien muokkaaminen onnistui.', class: 'Success' })
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    } else {
                        count++
                    }
                }).catch(() => {
                    setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
                    window.scrollTo({ top: 0, behavior: "smooth" })
                })
            )
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Muokkaa tekstejä</h1>
            <form onSubmit={ handleFormSubmit }>
                { texts && texts.length > 0 && texts.map(text => {
                    return (
                        <Textarea
                            key = { text.id }
                            name = { text.label }
                            value = { text.value }
                            rows = { 5 }
                            title = { text.label }
                            handlechange = { (e) => handleTexts(text.id, e.target.value) }
                        />
                    )
                })}
                <Button 
                    action = { handleFormSubmit }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default EditTexts