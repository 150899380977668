import React from 'react';

import './checkbox.scss';

const CategoryCheckbox = (props) => (
    <div className={ props.selected.filter(i => i.id === props.category.parent_category).length > 0 ?  'Checkbox Show Level-' + props.category.level : 'Checkbox Level-' + props.category.level }>
        <input 
            type = 'checkbox' 
            id = { 'category_' + props.category.id } 
            value = { props.value ? props.value : props.category.id } 
            name = { props.category.name } 
            checked = { props.checked ? props.checked : '' } 
            onChange = { props.action } 
        />
        <label htmlFor={ 'category_' + props.category.id }>{ props.category.name }</label>
    </div>
)

export default CategoryCheckbox