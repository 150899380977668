import React, { useState, useEffect, useContext, useCallback } from 'react'
import axios from 'axios';

import Input from '../../components/formElements/input/input';
import ImageInput from '../../components/formElements/imageInput/imageInput';
import ImagePreview from '../../components/formElements/imagePreview/imagePreview';
import Button from '../../components/formElements/button/button';
import AuthContext from '../../stores/authContext';
import MainContent from '../../components/UI/mainContent/mainContent';
import Sidebar from '../../components/UI/sidebar/sidebar';
import Layout from '../../components/UI/layout/layout';
import Message from '../../components/message/message';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import Textarea from '../../components/formElements/textarea/textarea';
import TextBlock from '../../components/blocks/textBlock/textBlock';
import ImageBlock from '../../components/blocks/imageBlock/imageBlock';
import GuideContext from '../../stores/guideContext';
import FileBlock from '../../components/blocks/fileBlock/fileBlock';
import Wrapper from '../../components/UI/wrapper/wrapper';
import VideoBlock from '../../components/blocks/videoBlock/videoBlock';
import Spinner from '../../components/UI/spinner/spinner';
import PolicyValidation from '../../validation/policyValidation';
import SidebarLeft from '../../components/UI/sidebarLeft/sidebarLeft';
import Dropdown from '../../components/UI/dropdown/dropdown';
import Tag from '../../components/tag/tag';
import InputInteger from '../../components/formElements/input/inputInteger';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';

function CreatePolicies(props) {
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [weight, setWeight] = useState(0)
    const [description, setDescription] = useState('')
    const [id, setID] = useState('')
    const [published, setPublished] = useState(0)
    const [image, setImage] = useState('')
    const [imageID, setImageID] = useState('')
    const [imageName, setImageName] = useState('')
    const [categories, setCategories] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState('')
    const [selectedTags, setSelectedTags] = useState('')
    const [roles, setRoles] = useState('')
    const [selectedRoles, setSelectedRoles] = useState('')
    const [invalidFields, setInvalidFields] = useState([])
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const { user, message, setMessage } = useContext(AuthContext)
    const { pageBlocks, setPageBlocks, pageContent, setPageContent, handleSetPageBlocks, getNextKey, getNextId, clearState } = useContext(GuideContext)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        clearState()
    }, [clearState])

    const getPageContent = useCallback(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setName(res.data.name)
                setWeight(res.data.weight)
                setDescription(res.data.description)
                setImageID(res.data.image_id)
                setImageName(res.data.path)
                setID(res.data.id)
                setPublished(res.data.published)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/content`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data[0].id) {
                    let content = res.data
                    let blocks = []
                    content.map(item => {
                        if (item.type === 'text-block') {
                            return blocks.push(<TextBlock 
                                key = { item.id } 
                                id = { item.id }
                                initialValue = { item.value }
                                parent = { item.parent }
                            />)
                        } else if (item.type === 'image-block') {
                            return blocks.push(<ImageBlock 
                                key = { item.id } 
                                id = { item.id }
                                initialValue = { JSON.parse(item.value) }
                                parent = { item.parent }
                            />)
                        } else if (item.type === 'video-block') {
                            return blocks.push(<VideoBlock
                                key = { item.id } 
                                id = { item.id }
                                initialValue = { item.value }
                                parent = { item.parent}
                            />)
                        } else if (item.type === 'file-block') {
                            return blocks.push(<FileBlock
                                key = { item.id } 
                                id = { item.id }
                                initialValue = { JSON.parse(item.value) }
                                parent = { item.parent }
                            />)
                        } else {
                            return null
                        }
                    })
        
                    setPageContent(content)
                    setPageBlocks(blocks)
                }
                
                setLoading(false)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data[0].id && setSelectedCategories(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/tags`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data[0].id && setSelectedTags(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${props.match.params.machine_name}/policy_roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data[0].id && setSelectedRoles(res.data)
            })
        }
    }, [user, props.match.params.machine_name, setPageBlocks, setPageContent])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                const sortedByParent = res.data.sort((a, b) => a.parent_category > b.parent_category ? 1 : -1)
                const sortedCategories = []
                sortedByParent.map(category => {
                    const parentIndex  = sortedByParent.findIndex(i => i.id === category.parent_category)
                    if (!category.parent_category) {
                        category.level = 1
                        return sortedCategories.push(category)
                    } else if (sortedByParent[parentIndex] && sortedByParent[parentIndex].level === 1) {
                        category.level = 2   
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)      
                    } else {
                        category.level = 3
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)
                    }
                })
                setCategories(sortedCategories.reverse())
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
        }

        if (props.match.params.machine_name) {
            getPageContent()
        }

    }, [user, props.match.params.machine_name, getPageContent])

    useEffect(() => {
        setMachineName(name.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase())
    }, [name, specialChars])

    useEffect(() => {
        if (image && user && user.token) {
            const fd = new FormData()
            fd.append('image', image, image.name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setMessage({ message: res.data.error, class: 'Error' })
                    window.scrollTo({ top: 0, behavior: "smooth" })
                } else {
                    setImageID(res.data.id)
                    setImageName(res.data.name)
                }
            })
        }
    }, [image, user, setMessage])

    useEffect(() => {
        selectedCategories.map(item => {
            if ((item.level === 2 || item.level === 3) && selectedCategories.filter(i => i.id === item.parent_category).length === 0) {
                return setSelectedCategories(prevState => [...prevState.slice(0, selectedCategories.indexOf(item)), ...prevState.slice(selectedCategories.indexOf(item) + 1)])
            } else {
                return null
            }
        })
    }, [selectedCategories])

    useEffect(() => {
        if (tag.length > 1) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/tags/${tag}`).then(res => {
                setTags(res.data)
            })
        } else {
            setTags('')
        }
    }, [tag])

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleSelectTags(tag) {
        if (selectedTags && selectedTags.filter(i => i.name === tag.name).length > 0) {
            const index = selectedTags.findIndex(i => i.name === tag.name)
            setSelectedTags(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedTags(prevState => [...prevState, tag])
        }
    }

    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function addTagHandler(e) {
        e.preventDefault()
        if (tags && tags.filter(i => i.name === tag).length > 0) {
            handleSelectTags(tags[tags.findIndex(i => i.name === tag)])
        } else {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/tags`, {
                name: tag
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSelectedTags(prevState => [...prevState, res.data])
            })
            .catch(() => {
                setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            })
        }
    }

    function handleFormValidation(e, publish) {
        e.preventDefault()
        const validation = PolicyValidation(name, machineName, weight, description, selectedCategories)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit(publish)
        }
    }

    function handleFormSubmit(publish) {
        if (publish) {
            setPublished(1)
        } else {
            setPublished(publish)
        }

        if (pageContent && pageContent.length > 0) {
            let count = 0
            pageContent.map(content => {
                count++
                return (
                    content.position = count
                )
            })
        }

        const data = {
            name: name,
            machine_name: machineName,
            weight: weight,
            description: description,
            image_id: imageID ? imageID : 1,
            categories: selectedCategories,
            tags: selectedTags,
            roles: selectedRoles,
            published: publish,
            policy_content: pageContent
        }

        if (!props.match.params.machine_name) {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/policies`, data, {
                headers: {
                    'auth-token': user.token
                }
            }).then(() => {
                clearState()
                setMessage({ message: 'Toimintatavan lisääminen onnistui.', class: 'Success' })
                props.history.push('/toimintatavat')
            }).catch(() => {
                setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            })
        } else {
            setLoading(true)
            axios.patch(`${process.env.REACT_APP_API_PATH}/api/admin/policies/${id}`, data, {
                headers: {
                    'auth-token': user.token
                }
            }).then(() => {
                getPageContent()
                setMessage({ message: 'Toimintatavan muokkaaminen onnistui.', class: 'Success' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            }).catch(() => {
                setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
                setLoading(false)
                window.scrollTo({ top: 0, behavior: "smooth" })
            })
        }
    }

    return (
        <Layout>
            {!loading?
                <div className='GuideForm'>
                    <SidebarLeft>
                        <div className='AddBlocksWrapper'>
                            <h2>Lisää sisältölohkoja</h2>
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <TextBlock 
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                    />, 'text-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tekstialue'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <ImageBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'image-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Kuva'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <FileBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'file-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tiedosto'
                            />
                            <Button 
                                action = { () => handleSetPageBlocks(
                                    <VideoBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { 0 }
                                        showEdit = { true }
                                    />, 'video-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Video'
                            />
                        </div>
                    </SidebarLeft>
                    <MainContent classes='Narrow'>
                        { message &&
                            <Message 
                                classes = { message.class }
                                message = { message.message }
                                closeMessage = { () => setMessage(null) }
                            />
                        }
                        <h1>{ props.match.params.machine_name ? 'Muokkaa toimintatapaa' : 'Lisää toimintatapa' }</h1>
                        <Input 
                            inputtype = 'text'
                            name = 'name'
                            title = 'Otsikko'
                            value = { name }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setName(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        <Input 
                            inputtype = 'text'
                            name = 'machineName'
                            title = 'Koneluettava otsikko'
                            value = { machineName }
                            placeholder = 'Otsikko'
                            handlechange = { 
                                (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                            }
                            invalidFields = { invalidFields }
                        />
                        <ImageInput
                            name = 'image'
                            title = 'Kuva'
                            handlechange = { (e) => setImage(e.target.files[0]) }
                            invalidFields = { invalidFields }
                        />
                        { imageID && 
                            <ImagePreview 
                                image = { `${process.env.REACT_APP_API_PATH}/images/${imageName}` }
                                removeImage = { () => setImageID(null) }
                            />
                        }
                        <InputInteger 
                            inputtype = 'number'
                            name = 'weight'
                            title = 'Paino'
                            value = { weight }
                            placeholder = 'Paino'
                            handlechange = { (e) => setWeight(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        <Textarea 
                            name = 'description'
                            value = { description }
                            rows = { 5 }
                            title = 'Lyhyt kuvaus'
                            handlechange = { (e) => setDescription(e.target.value) }
                            placeholder = 'Lyhyt kuvaus'
                            invalidFields = { invalidFields }
                        />
                        { pageBlocks && pageBlocks.filter(i => i.props.parent === 0) }
                        { props.match.params.machine_name &&
                            <p><strong>Tila: </strong> { published === 1 ? 'Julkaistu' : 'Julkaisematon' }</p>
                        }
                        <Button 
                            action = { (e) => handleFormValidation(e, published) }
                            type = 'btn btn-primary'
                            title = 'Tallenna'
                        />
                        { published === 1 ?
                            <Button 
                                action = { (e) => handleFormValidation(e, 0) }
                                type = 'btn btn-primary'
                                title = 'Poista julkaisu'
                            />
                        :
                            <Button 
                                action = { (e) => handleFormValidation(e, 1) }
                                type = 'btn btn-primary'
                                title = 'Tallenna ja julkaise'
                            />
                        }
                        { props.match.params.machine_name &&
                            <Button 
                                action = { () => props.history.push(`/toimintatavat/poista/${props.match.params.machine_name}`) }
                                type = 'btn btn-danger btn-right'
                                title = 'Poista'
                            />
                        }     
                    </MainContent>
                    <Sidebar>
                        <Wrapper 
                            name = 'selectedCategories'
                            invalidFields = { invalidFields }
                        >
                            <h2>Kategoriat</h2>
                            { categories &&
                                categories.map(category => {
                                    return (
                                        <CategoryCheckbox 
                                            key = { category.id }
                                            category = { category }
                                            action = { () =>  handleSelectCategories(category) }
                                            selected = { selectedCategories }
                                            checked = {
                                                selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                                    true 
                                                : null
                                            }
                                        />
                                    )
                                }) 
                            }
                        </Wrapper>
                        <Wrapper>
                            <h2>Avainsanat</h2>
                            <form autoComplete='off' onSubmit={ (e) => addTagHandler(e) }>
                                <Input 
                                    inputtype = 'text'
                                    name = 'tag'
                                    value = { tag }
                                    placeholder = 'Avainsana'
                                    handlechange = { (e) => setTag(e.target.value) }
                                />
                            </form>
                            { tags && tags.length > 0 &&
                                <Dropdown>
                                    {tags.map(tag => {
                                        return (
                                            <Tag 
                                                key = { tag.name }
                                                tag = { tag }
                                                action = { () => handleSelectTags(tag) }
                                            />
                                        )
                                    })}
                                </Dropdown>
                            }
                            <h3>Valitut avainsanat</h3>
                            { selectedTags &&
                                selectedTags.map(tag => {
                                    return (
                                        <Tag 
                                            key = { tag.name }
                                            tag = { tag }
                                            action = { () => handleSelectTags(tag) }
                                        />
                                    )                        
                                })
                            }
                        </Wrapper>
                        <Wrapper
                            name = 'selectedRoles'
                            invalidFields = { invalidFields }
                        >
                            <h2>Käyttäjäryhmät</h2>
                            { roles &&
                                roles.map(role => {
                                    return (
                                        <RoleCheckbox 
                                            key = { role.id }
                                            item = { role }
                                            action = { () =>  handleSelectRoles(role) }
                                            checked = {
                                                selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                                    true 
                                                : null
                                            }
                                        />
                                    )
                                })
                            }
                        </Wrapper>
                    </Sidebar>
                </div>
            :
                <Spinner />
            }
        </Layout>
    )
}

export default CreatePolicies