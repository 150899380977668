import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';
import Select from '../../components/formElements/select/select';

function UsersIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [users, setUsers] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Poista' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [count, setCount] = useState('')
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getUsers() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/users/${firstItem}/${itemsToShow}/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setUsers(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/users/count/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setCount(res.data[0].item_count)
        })
    }

    useEffect(() => {
        if (user && user.token) {
            getUsers()
        }
    }, [user, filter, firstItem, itemsToShow])

    useEffect(() => {
        setFirstItem(0)
    }, [filter])

    function handleItemSelection(id) {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(i => i !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    function selectAllItems() {
        if (document.getElementById('SelectAllItems').checked) {
            let itemArray = []
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = true
                itemArray.push(parseInt(checkbox.value))
            })
            setSelectedItems(itemArray)
        } else {
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = false
                setSelectedItems([])
            })
        }
    }

    function unSelectAllItems() {
        document.getElementById('SelectAllItems').checked = false
        document.querySelectorAll('.SelectItem').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedItems([])
    }

    function handleMassEdit() {
        if (selectedItems.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse käyttäjät' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/users/delete`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Käyttäjät poistettu' })
                getUsers()
                unSelectAllItems()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Käyttäjät</h1>
            <Button 
                action = { () => props.history.push('/kayttajat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <div className='GuideFilters'>
                <Select 
                    name = 'massEdit'
                    value = { massEdit }
                    options = { massEditOptions }
                    handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                    placeholder = 'Massamuokkaus'
                />
                <Button
                    action = { () => handleMassEdit() }
                    type = 'btn btn-success btn-mass-edit'
                    title = 'Suorita'
                />
                <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            </div>
            { users &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllItems" onChange={() => selectAllItems()} type='checkbox' /></th>
                            <th>Nimi</th>
                            <th>Rooli</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { users.map(user => {
                            if (!filter || user.name.toLowerCase().includes(filter.toLowerCase())) {
                                return (
                                    <tr key={ user.id }>
                                        <td><input className="SelectItem" onChange={ () => handleItemSelection(user.id)
                                        } type='checkbox' value={ user.id } checked={ selectedItems.includes(user.id) ? true : false } /></td>
                                        <td>{ user.name }</td>
                                        <td>{ user.role_name }</td>
                                        <td>{ `${new Date(user.created_at).getDate()}.${new Date(user.created_at).getMonth() + 1}.${new Date(user.created_at).getFullYear()}` }</td>
                                        <td>{ `${new Date(user.updated_at).getDate()}.${new Date(user.updated_at).getMonth() + 1}.${new Date(user.updated_at).getFullYear()}` }</td>
                                        <td>
                                            <NavLink to={`/kayttajat/muokkaa/${user.id}`}>Muokkaa</NavLink>
                                            <NavLink to={`/kayttajat/poista/${user.id}`}>Poista</NavLink>
                                        </td>
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        }) }
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { count }
                filter = { filter }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default UsersIndex