import React from 'react';

import './tag.scss';

const Tag = (props) => (
    <div className='TagWrapper'>
        <button className='Tag' onClick={ props.action }>
            { props.tag.name }
        </button>
    </div>
)

export default Tag