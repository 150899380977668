import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronUp, faChevronDown, faChevronLeft, faArrowUp, faArrowDown, faArrowsAlt, faThumbsUp, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import './app.scss';
import Frontpage from './containers/frontpage';
import GuidesIndex from './containers/guides';
import CreateGuides from './containers/guides/create';
import Login from './containers/login';
import AuthContext from './stores/authContext';
import CategoriesIndex from './containers/categories';
import CreateCategories from './containers/categories/create';
import CreatePolicies from './containers/policies/create';
import PoliciesIndex from './containers/policies';
import CreateUsers from './containers/users/create';
import UsersIndex from './containers/users';
import DeleteGuides from './containers/guides/delete';
import ShowGuides from './containers/guides/show';
import EditCategories from './containers/categories/edit';
import DeleteCategories from './containers/categories/delete';
import CreatePolicyCategories from './containers/policy_categories/create';
import DeletePolicyCategories from './containers/policy_categories/delete';
import PolicyCategoriesIndex from './containers/policy_categories';
import EditPolicyCategories from './containers/policy_categories/edit';
import DeletePolicies from './containers/policies/delete';
import ShowPolicies from './containers/policies/show';
import CreateSymptoms from './containers/symptoms/create';
import DeleteSymptoms from './containers/symptoms/delete';
import SymptomsIndex from './containers/symptoms';
import EditSymptoms from './containers/symptoms/edit';
import DeleteProducts from './containers/products/delete';
import ProductsIndex from './containers/products';
import CreateProducts from './containers/products/create';
import EditProducts from './containers/products/edit';
import EditProductCategories from './containers/product_categories/edit';
import DeleteProductCategories from './containers/product_categories/delete';
import CreateProductCategories from './containers/product_categories/create';
import ProductCategoriesIndex from './containers/product_categories';
import EditTags from './containers/tags/edit';
import CreateTags from './containers/tags/create';
import TagsIndex from './containers/tags';
import DeleteTags from './containers/tags/delete';
import OrdersIndex from './containers/orders';
import ShowOrders from './containers/orders/show';
import EditUsers from './containers/users/edit';
import DeleteUsers from './containers/users/delete';
import RolesIndex from './containers/roles';
import CreateRoles from './containers/roles/create';
import EditRoles from './containers/roles/edit';
import DeleteRoles from './containers/roles/delete';
import EditGuides from './containers/guides/edit';
import EditSettings from './containers/settings/edit';
import ReportsIndex from './containers/reports';
import ShowReports from './containers/reports/show';
import CreateHouseFiles from './containers/house_files/create';
import EditHouseFiles from './containers/house_files/edit';
import DeleteHouseFiles from './containers/house_files/delete';
import HouseFilesIndex from './containers/house_files';
import HousesIndex from './containers/houses';
import CreateHouseCategories from './containers/house_categories/create';
import HouseCategoriesIndex from './containers/house_categories';
import EditHouseCategories from './containers/house_categories/edit';
import DeleteHouseCategories from './containers/house_categories/delete';
import CreateHouseImages from './containers/house_images/create';
import EditHouseImages from './containers/house_images/edit';
import DeleteHouseImages from './containers/house_images/delete';
import HouseImagesIndex from './containers/house_images';
import CreateHouseVideos from './containers/house_videos/create';
import HouseVideosIndex from './containers/house_videos';
import EditHouseVideos from './containers/house_videos/edit';
import DeleteHouseVideos from './containers/house_videos/delete';
import UnitsIndex from './containers/units';
import CreateUnits from './containers/units/create';
import DeleteUnits from './containers/units/delete';
import EditUnits from './containers/units/edit';
import HouseTabsIndex from './containers/house_tabs';
import EditHouseTabs from './containers/house_tabs/edit';
import TextsIndex from './containers/texts';
import EditTexts from './containers/texts/edit';
import CreateHouseTabs from './containers/house_tabs/create';
import DeleteHouseTabs from './containers/house_tabs/delete';

library.add(faChevronRight, faChevronUp, faChevronDown, faChevronLeft, faArrowUp, faArrowDown, faArrowsAlt, faThumbsUp, faBars, faTimes)

function App() {
    const { user, authReady } = useContext(AuthContext)
    const [routes, setRoutes] = useState('')

    useEffect(() => {
        if (user && authReady && user.role === 1) {
            setRoutes(
                <Switch>
                    <Route exact path='/' component={ Frontpage } />
                    <Route path='/ohjeet/lisaa' component={ CreateGuides } />
                    <Route path='/ohjeet/muokkaa/:machine_name' component={ EditGuides } />
                    <Route path='/ohjeet/poista/:machine_name' component={ DeleteGuides } />
                    <Route path='/ohjeet/oireet/muokkaa/:id' component={ EditSymptoms } />
                    <Route path='/ohjeet/oireet/poista/:id' component={ DeleteSymptoms } />
                    <Route path='/ohjeet/oireet/lisaa' component={ CreateSymptoms } />
                    <Route path='/ohjeet/oireet' component={ SymptomsIndex } />
                    <Route path='/ohjeet/kategoriat/muokkaa/:id' component={ EditCategories } />
                    <Route path='/ohjeet/kategoriat/poista/:id' component={ DeleteCategories } />
                    <Route path='/ohjeet/kategoriat/lisaa' component={ CreateCategories } />
                    <Route path='/ohjeet/kategoriat' component={ CategoriesIndex } />
                    <Route path='/ohjeet/:machine_name' component={ ShowGuides } />
                    <Route path='/ohjeet' component={ GuidesIndex } />
                    <Route path='/tuotteet/lisaa' component={ CreateProducts } />
                    <Route path='/tuotteet/muokkaa/:id' component={ EditProducts } />
                    <Route path='/tuotteet/poista/:id' component={ DeleteProducts } />
                    <Route path='/tuotteet/kategoriat/muokkaa/:id' component={ EditProductCategories } />
                    <Route path='/tuotteet/kategoriat/poista/:id' component={ DeleteProductCategories } />
                    <Route path='/tuotteet/kategoriat/lisaa' component={ CreateProductCategories } />
                    <Route path='/tuotteet/kategoriat' component={ ProductCategoriesIndex } />
                    <Route path='/tuotteet/yksikot/muokkaa/:id' component={ EditUnits } />
                    <Route path='/tuotteet/yksikot/poista/:id' component={ DeleteUnits } />
                    <Route path='/tuotteet/yksikot/lisaa' component={ CreateUnits } />
                    <Route path='/tuotteet/yksikot' component={ UnitsIndex } />
                    <Route path='/tuotteet' component={ ProductsIndex } />
                    <Route path='/tilaukset/:id' component={ ShowOrders } />
                    <Route path='/tilaukset' component={ OrdersIndex } />
                    <Route path='/toimintatavat/kategoriat/muokkaa/:id' component={ EditPolicyCategories } />
                    <Route path='/toimintatavat/kategoriat/poista/:id' component={ DeletePolicyCategories } />
                    <Route path='/toimintatavat/kategoriat/lisaa' component={ CreatePolicyCategories } />
                    <Route path='/toimintatavat/kategoriat' component={ PolicyCategoriesIndex } />
                    <Route path='/toimintatavat/lisaa' component={ CreatePolicies } />
                    <Route path='/toimintatavat/muokkaa/:machine_name' component={ CreatePolicies } />
                    <Route path='/toimintatavat/poista/:machine_name' component={ DeletePolicies } />
                    <Route path='/toimintatavat/:machine_name' component={ ShowPolicies } />
                    <Route path='/toimintatavat' component={ PoliciesIndex } />
                    <Route path='/kayttajat/muokkaa/:id' component={ EditUsers } />
                    <Route path='/kayttajat/poista/:id' component={ DeleteUsers } />
                    <Route path='/kayttajat/lisaa' component={ CreateUsers } />
                    <Route path='/kayttajat' component={ UsersIndex } />
                    <Route path='/avainsanat/muokkaa/:id' component={ EditTags } />
                    <Route path='/avainsanat/poista/:id' component={ DeleteTags } />
                    <Route path='/avainsanat/lisaa' component={ CreateTags } />
                    <Route path='/avainsanat' component={ TagsIndex } />
                    <Route path='/kayttajaryhmat/muokkaa/:id' component={ EditRoles } />
                    <Route path='/kayttajaryhmat/poista/:id' component={ DeleteRoles } />
                    <Route path='/kayttajaryhmat/lisaa' component={ CreateRoles } />
                    <Route path='/kayttajaryhmat' component={ RolesIndex } />
                    <Route path='/asetukset' component={ EditSettings } />
                    <Route path='/vikailmoitukset/:id' component={ ShowReports } />
                    <Route path='/vikailmoitukset' component={ ReportsIndex } />
                    <Route path='/kiinteistot/tiedostot/muokkaa/:id' component={ EditHouseFiles } />
                    <Route path='/kiinteistot/tiedostot/poista/:id' component={ DeleteHouseFiles } />
                    <Route path='/kiinteistot/tiedostot/lisaa' component={ CreateHouseFiles } />
                    <Route path='/kiinteistot/tiedostot' component={ HouseFilesIndex } />
                    <Route path='/kiinteistot/kategoriat/muokkaa/:id' component={ EditHouseCategories } />
                    <Route path='/kiinteistot/kategoriat/poista/:id' component={ DeleteHouseCategories } />
                    <Route path='/kiinteistot/kategoriat/lisaa' component={ CreateHouseCategories } />
                    <Route path='/kiinteistot/kategoriat' component={ HouseCategoriesIndex } />
                    <Route path='/kiinteistot/kuvat/muokkaa/:id' component={ EditHouseImages } />
                    <Route path='/kiinteistot/kuvat/poista/:id' component={ DeleteHouseImages } />
                    <Route path='/kiinteistot/kuvat/lisaa' component={ CreateHouseImages } />
                    <Route path='/kiinteistot/kuvat' component={ HouseImagesIndex } />
                    <Route path='/kiinteistot/videot/muokkaa/:id' component={ EditHouseVideos } />
                    <Route path='/kiinteistot/videot/poista/:id' component={ DeleteHouseVideos } />
                    <Route path='/kiinteistot/videot/lisaa' component={ CreateHouseVideos } />
                    <Route path='/kiinteistot/videot' component={ HouseVideosIndex } />
                    <Route path='/kiinteistot/valilehdet/muokkaa/:id' component={ EditHouseTabs } />
                    <Route path='/kiinteistot/valilehdet/poista/:id' component={ DeleteHouseTabs } />
                    <Route path='/kiinteistot/valilehdet/lisaa' component={ CreateHouseTabs } />
                    <Route path='/kiinteistot/valilehdet' component={ HouseTabsIndex } />
                    <Route path='/kiinteistot' component={ HousesIndex } />
                    <Route path='/tekstit/muokkaa/:id' component={ EditTexts } />
                    <Route path='/tekstit' component={ TextsIndex } />
                </Switch>
            )
        } else if (authReady) {
            setRoutes(
                <Switch>
                    <Route path='/' component={ Login } />
                </Switch>
            )
        }
    }, [user, authReady])

    return (
        <div className='App'>
            <div className='Content'>
                {routes}
            </div>
        </div>
    )
}

export default withRouter(props => <App {...props} />);