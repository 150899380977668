import Button from '../../components/formElements/button/button';
import Layout from '../../components/UI/layout/layout';

function HousesIndex(props) {
    return (
        <Layout>
            <h1>Kiinteistökohtaiset tiedot</h1>
            <Button 
                action = { () => props.history.push('/kiinteistot/valilehdet') }
                type = 'btn btn-primary'
                title = 'Välilehdet'
            />
            <Button 
                action = { () => props.history.push('/kiinteistot/kategoriat') }
                type = 'btn btn-primary'
                title = 'Kategoriat'
            />
            <Button 
                action = { () => props.history.push('/kiinteistot/kuvat') }
                type = 'btn btn-primary'
                title = 'Kuvat'
            />
            <Button 
                action = { () => props.history.push('/kiinteistot/tiedostot') }
                type = 'btn btn-primary'
                title = 'Tiedostot'
            />
            <Button 
                action = { () => props.history.push('/kiinteistot/videot') }
                type = 'btn btn-primary'
                title = 'Videot'
            />
        </Layout>
    )
}

export default HousesIndex