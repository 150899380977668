import React, { useContext } from 'react';

import './blockActions.scss';
import GuideContext from '../../../../stores/guideContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BlockActions(props) {
    const { pageBlocks, removeBlock, moveBlockDown, moveBlockUp } = useContext(GuideContext)

    return (
        <div className='BlockActions'>
            { props.showChevron && props.showBlocks &&
                <FontAwesomeIcon onClick={ props.handleSetShowBlocks } icon='chevron-up' />
            }
            { props.showChevron && !props.showBlocks &&
                <FontAwesomeIcon onClick={ props.handleSetShowBlocks } icon='chevron-down' />
            }
            { props.name &&
                <strong style={{ float: 'left' }}>{ props.name }</strong>
            }
            { props.showAddOption &&
                <button onClick={ props.addOption }>Lisää vaihtoehto</button>
            }
            { props.edit &&
                <button onClick={ props.editAction }>Muokkaa</button>
            }
            { pageBlocks.filter(i => i.props.parent === props.parent).length > 0 && pageBlocks.filter(i => i.props.parent === props.parent)[0].props.id !== props.id &&
                <React.Fragment>
                    <FontAwesomeIcon onClick={ () => moveBlockUp(props.id) } icon='arrow-up' />
                    <button onClick={ () => moveBlockUp(props.id) }>Ylös</button>
                </React.Fragment>
            }
            { pageBlocks.filter(i => i.props.parent === props.parent).length > 0 && pageBlocks.filter(i => i.props.parent === props.parent)[pageBlocks.filter(i => i.props.parent === props.parent).length - 1].props.id !== props.id &&
                <React.Fragment>
                    <button onClick={ () => moveBlockDown(props.id) }>Alas</button>
                    <FontAwesomeIcon onClick={ () => moveBlockDown(props.id) } icon='arrow-down' />
                </React.Fragment>
            }
            <button className='Remove' onClick={ () => removeBlock(props.id) }>Poista</button>
        </div>
    )
}

export default BlockActions