import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Select from '../../components/formElements/select/select';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import PolicyCategoryValidation from '../../validation/policyCategoryValidation';

function EditPolicyCategories(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [mainCategories, setMainCategories] = useState('')
    const [mainParentCategory, setMainParentCategory] = useState('')
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/policies/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                let main = res.data
                // remove category itself from main categories
                main = main.filter(i => i.id !== parseInt(props.match.params.id))
                setMainCategories(main)
                axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/categories/${props.match.params.id}`, {
                    headers: {
                        'auth-token': user.token
                    }
                }).then(res => {
                    setName(res.data.name)
                    setMachineName(res.data.machine_name)
                    if (main[main.findIndex(i => i.id === res.data.parent_category)]) {
                        setMainParentCategory(res.data.parent_category)
                    }
                })
            })
        }
    }, [user, props.match.params.id])

    function getMainCategories() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/policies/categories`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setMainCategories(res.data)
        })
    }

    function mainParentCategoryHandler(e) {
        setMainParentCategory(e.target.children[e.target.selectedIndex].value)
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = PolicyCategoryValidation(name, machineName)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {

        const data = {
            name: name,
            machine_name: machineName,
            parent_category: mainParentCategory ? mainParentCategory : 0,
            position: 0
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/admin/policies/categories/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            getMainCategories()
            setMessage({ message: 'Kategorian muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Muokkaa kategoriaa</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'name'
                    title = 'Nimi'
                    value = { name }
                    placeholder = 'Kategorian nimi'
                    handlechange = { (e) => setName(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'machineName'
                    title = 'Koneluettava nimi'
                    value = { machineName }
                    placeholder = 'Ohjeen nimi'
                    handlechange = { 
                        (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                    }
                    invalidFields = { invalidFields }
                />
                { mainCategories &&
                    <Select 
                        title = 'Pääkategoria'
                        name = 'mainParentCategory'
                        value = { mainParentCategory }
                        options = { mainCategories }
                        handlechange = { (e) => mainParentCategoryHandler(e) }
                        placeholder = { 'Valitse kategoria' }
                    />
                }
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default EditPolicyCategories