import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';
import HouseSelect from '../../components/formElements/select/houseSelect';

function HouseFilesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [files, setFiles] = useState([])
    const [filteredFiles, setFilteredFiles] = useState([])
    const [houses, setHouses] = useState([])
    const [selectedHouse, setSelectedHouse] = useState('')
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(30)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-files`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setFiles(res.data)
                setFilteredFiles(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses`).then(res => {
                setHouses(res.data)
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        files.map(file => {
            if ((!filter || file.label.toLowerCase().includes(filter.toLowerCase())) && (!selectedHouse || selectedHouse === file.house_id)) {
                return filtered.push(file)
            } else {
                return null
            }
        })
        setFilteredFiles(filtered)
    }, [filter, files, houses, selectedHouse])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Tiedostot</h1>
            <Button 
                action = { () => props.history.push('/kiinteistot/tiedostot/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <div className='GuideFilters'>
                <HouseSelect 
                    name = 'selectedHouse'
                    value = { selectedHouse }
                    options = { houses }
                    handlechange = { (e) => setSelectedHouse(parseInt(e.target.children[e.target.selectedIndex].value)) }
                    placeholder = { 'Kiinteistö' }
                />
                
                <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            </div>
            { files && files.length > 0 &&
                <Table>
                    <thead>
                        <tr>
                            <th>Tiedosto</th>
                            <th>Otsikko</th>
                            <th>Kiinteistö</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { filteredFiles.slice(0).splice(firstItem, itemsToShow).map(file => {
                            return (
                                <tr key={ file.id }>
                                    <td>
                                        { file.file_name }
                                    </td>
                                    <td>
                                        { file.label }
                                    </td>
                                    <td>
                                        { houses.filter(i => i.house_id === file.house_id).length > 0 && houses.filter(i => i.house_id === file.house_id)[0].nimi }
                                    </td>
                                    <td>
                                        <NavLink to={`/kiinteistot/tiedostot/muokkaa/${file.id}`}>
                                            Muokkaa
                                        </NavLink>
                                        <NavLink to={`/kiinteistot/tiedostot/poista/${file.id}`}>
                                            Poista
                                        </NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredFiles.length }
                filter = { filter }
                filter2 = { selectedHouse }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default HouseFilesIndex