import React, { useContext, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';

import './textBlock.scss';
import GuideContext from '../../../stores/guideContext';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../../stores/authContext';

function TextBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd } = useContext(GuideContext)
    const [draggable, setDraggable] = useState(false)
    const [count, setCount] = useState('')
    const { user } = useContext(AuthContext)

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'text-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    const handleChange = (content, e) => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === e.settings.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === e.settings.id)], { value: content, type: 'text-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === e.settings.id) + 1)
            ]
        )
    }

    const handleImage = (blobInfo, success, failure) => {
        const fd = new FormData()
        fd.append('image', blobInfo.blob(), blobInfo.filename());
        axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            if (res.data.error) {
                return failure(res.data.error)
            } else {
                return success(`${process.env.REACT_APP_API_PATH}/images/${res.data.name}`)
            }
        })
    }

    return (
        <div 
            className = 'TextBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    name = { 'Tekstialue ' + count } 
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            <Editor
                initialValue = { props.initialValue ? props.initialValue : '' }
                init = {{
                    height: 150,
                    menubar: false,
                    relative_urls: false,
                    plugins: [
                        'print preview  searchreplace autolink directionality  visualblocks visualchars fullscreen link table charmap hr pagebreak nonbreaking insertdatetime advlist lists wordcount textpattern textcolor colorpicker image media'
                    ],
                    toolbar:
                        'formatselect | bold italic | forecolor backcolor | link removeformat | alignleft aligncenter alignright | numlist bullist | undo redo | image | media',
                    file_picker_types: 'image',
                    images_upload_handler: (blobInfo, success, failure) => handleImage(blobInfo, success, failure),
                    branding: false,
                    id: props.id,
                    inline: true
                }}
                onEditorChange = { (content, e) => handleChange(content, e) }
            />
        </div>
    )
}

export default TextBlock