import React, { useState, useEffect, useContext } from 'react';

import '../productOption.scss';
import Input from '../../formElements/input/input';
import ProductContext from '../../../stores/productContext';
import HouseSelect from '../../formElements/select/houseSelect';

function HouseOption(props) {
    const [house, setHouse] = useState('')
    const [price, setPrice] = useState('')
    const { setHouseValues, removeHouse } = useContext(ProductContext)

    useEffect(() => {
        props.house && setHouse(props.house)
        props.price && setPrice(props.price)
    }, [props.house, props.price])

    useEffect(() => {
        setHouseValues(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { house: house, price: price }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [house, price, props.id, setHouseValues])

    return (
        <div className='ProductOption HouseOption'>
            <div className='TopBar'>
                <button className='Remove' onClick={ () => removeHouse(props.id) }>Poista</button>
            </div>
            <HouseSelect 
                title = 'Taloyhtiö'
                name = 'house'
                value = { house.id }
                options = { props.houses }
                handlechange = { (e) => setHouse({ 
                    name: e.target.children[e.target.selectedIndex].label, 
                    id: e.target.children[e.target.selectedIndex].value }
                )}
                placeholder = { 'Taloyhtiö' }
            />
            <Input 
                inputtype = 'number'
                name = 'price'
                title = 'Hinta'
                value = { price }
                placeholder = 'Hinta'
                handlechange = { (e) => setPrice(e.target.value) }
            />
        </div>
    )
}

export default HouseOption