import React from 'react';

import './checkbox.scss';

const CategoryTypeCheckbox = (props) => (
    <div className={ props.selected.filter(i => i.id === props.item.parent_category).length > 0 ?  'Checkbox Show Level-' + props.item.level : 'Checkbox Level-' + props.item.level }>
        <input 
            type = 'checkbox' 
            id = { 'category_type_' + props.item.id } 
            value = { props.value ? props.value : props.item.id } 
            name = { props.item.name } 
            checked = { props.checked ? props.checked : '' } 
            onChange = { props.action } 
        />
        <label htmlFor={ 'category_type_' + props.item.id }>{ props.item.name }</label>
    </div>
)

export default CategoryTypeCheckbox