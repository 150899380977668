import { useContext, useState, useEffect } from 'react';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';
import Button from '../../components/formElements/button/button';
import Select from '../../components/formElements/select/select';

function OrdersIndex() {
    const { message, setMessage, user } = useContext(AuthContext)
    const [orders, setOrders] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Poista' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getOrders() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/${firstItem}/${itemsToShow}/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setOrders(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/count/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setCount(res.data[0].item_count)
        })
    }

    useEffect(() => {
        if (user && user.token) {
            getOrders()
        }
    }, [user, filter, firstItem, itemsToShow])

    useEffect(() => {
        setFirstItem(0)
    }, [filter])

    function handleItemSelection(id) {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(i => i !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    function selectAllItems() {
        if (document.getElementById('SelectAllItems').checked) {
            let itemArray = []
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = true
                itemArray.push(parseInt(checkbox.value))
            })
            setSelectedItems(itemArray)
        } else {
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = false
                setSelectedItems([])
            })
        }
    }

    function unSelectAllItems() {
        document.getElementById('SelectAllItems').checked = false
        document.querySelectorAll('.SelectItem').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedItems([])
    }

    function handleMassEdit() {
        if (selectedItems.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse tilaukset' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/orders/delete`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Tilaukset poistettu' })
                getOrders()
                unSelectAllItems()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Tilaukset</h1>
            <div className='GuideFilters'>
                <Select 
                    name = 'massEdit'
                    value = { massEdit }
                    options = { massEditOptions }
                    handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                    placeholder = 'Massamuokkaus'
                />
                <Button
                    action = { () => handleMassEdit() }
                    type = 'btn btn-success btn-mass-edit'
                    title = 'Suorita'
                />
                <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            </div>
            { orders &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllItems" onChange={() => selectAllItems()} type='checkbox' /></th>
                            <th>Tilausnumero</th>
                            <th>Osoite</th>
                            <th>Tilannut</th>
                            <th>Hinta</th>
                            <th>Tila</th>
                            <th>Lisätty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { orders.map(order => {
                            return (
                                <tr key={ order.id }>
                                    <td><input className="SelectItem" onChange={ () => handleItemSelection(order.id)
                                    } type='checkbox' value={ order.id } checked={ selectedItems.includes(order.id) ? true : false } /></td>
                                    <td>{ order.order_number }</td>
                                    <td>{ order.address }</td>
                                    <td>{ order.name }</td>
                                    <td>{ order.price + ' €' }</td>
                                    <td>{ order.order_status }</td>
                                    <td>{ `${new Date(order.created_at).getDate()}.${new Date(order.created_at).getMonth() + 1}.${new Date(order.created_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/tilaukset/${order.id}`}>Näytä</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { count }
                filter = { filter }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default OrdersIndex