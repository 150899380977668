const CategoryValidation = (name, machineName, selectedCategoryTypes) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        },
        machineName: {
            condition: machineName,
            message: 'Koneluettava otsikko on pakollinen kenttä'
        },
        selectedCategoryTypes: {
            condition: selectedCategoryTypes && selectedCategoryTypes.length > 0,
            message: 'Valitse kategorian tyyppi'
        }
    })

    return validation
}

export default CategoryValidation