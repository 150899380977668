import React from 'react';

import './message.scss';

const Message = (props) => (
    <div className={ props.classes ? props.classes + ' Message' : 'Message' }>
        <div className='CloseMessage'>
            <button onClick={ props.closeMessage }>Sulje</button>
        </div>
        <div className='MessageWrapper'>
            <p>{ props.message }</p>
        </div>
    </div>
)

export default Message