import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../stores/authContext';
import Container from '../UI/container/container';
import './header.scss';
import { useCurrentWidth } from '../../custom/useCurrentWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header(props) {
    const { logout } = useContext(AuthContext)
    let width = useCurrentWidth()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [activeDropdown, setActiveDropdown] = useState('')

    return (
        <div className='Header'>
            <Container>
                { width === 0 || width > 992 ?
                    <div className='Nav'>
                        <ul className='NavLeft'>
                            <li>
                                <Link className={ window.location.pathname === '/' ? 'Active' : null } to='/'>
                                    Etusivu
                                </Link>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/ohjeet' ? 'Active' : null } to='/ohjeet'>
                                        Työohjeet
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/ohjeet/lisaa' ? 'Active' : null } to='/ohjeet/lisaa'>
                                                Lisää uusi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/ohjeet/kategoriat' ? 'Active' : null } to='/ohjeet/kategoriat'>
                                                Kategoriat
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/ohjeet/oireet' ? 'Active' : null } to='/ohjeet/oireet'>
                                                Oireet
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/avainsanat' ? 'Active' : null } to='/avainsanat'>
                                                Avainsanat
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/tuotteet' ? 'Active' : null } to='/tuotteet'>
                                        Tuotteet
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/tuotteet/lisaa' ? 'Active' : null } to='/tuotteet/lisaa'>
                                                Lisää uusi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/tuotteet/kategoriat' ? 'Active' : null } to='/tuotteet/kategoriat'>
                                                Kategoriat
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/tuotteet/yksikot' ? 'Active' : null } to='/tuotteet/yksikot'>
                                                Yksiköt
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link className={ window.location.pathname === '/vikailmoitukset' ? 'Active' : null } to='/vikailmoitukset'>
                                    Vikailmoitukset
                                </Link>
                            </li>
                            <li>
                                <Link className={ window.location.pathname === '/tilaukset' ? 'Active' : null } to='/tilaukset'>
                                    Tilaukset
                                </Link>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/toimintatavat' ? 'Active' : null } to='/toimintatavat'>
                                        Toimintatavat
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/toimintatavat/lisaa' ? 'Active' : null } to='/toimintatavat/lisaa'>
                                                Lisää uusi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/toimintatavat/kategoriat' ? 'Active' : null } to='/toimintatavat/kategoriat'>
                                                Kategoriat
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/kiinteistot' ? 'Active' : null } to='/kiinteistot'>
                                        Kiinteistöt
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/kiinteistot/valilehdet' ? 'Active' : null } to='/kiinteistot/valilehdet'>
                                                Välilehdet
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/kiinteistot/kategoriat' ? 'Active' : null } to='/kiinteistot/kategoriat'>
                                                Kategoriat
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/kiinteistot/kuvat' ? 'Active' : null } to='/kiinteistot/kuvat'>
                                                Kuvat
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/kiinteistot/tiedostot' ? 'Active' : null } to='/kiinteistot/tiedostot'>
                                                Tiedostot
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className={ window.location.pathname === '/kiinteistot/videot' ? 'Active' : null } to='/kiinteistot/videot'>
                                                Videot
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/kayttajat' ? 'Active' : null } to='/kayttajat'>
                                        Käyttäjät
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/kayttajat/lisaa' ? 'Active' : null } to='/kayttajat/lisaa'>
                                                Lisää uusi
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='DropdownContainer'>
                                    <Link className={ window.location.pathname === '/kayttajaryhmat' ? 'Active' : null } to='/kayttajaryhmat'>
                                        Käyttäjäryhmät
                                    </Link>
                                    <ul className='NavDropdown'>
                                        <li>
                                            <Link className={ window.location.pathname === '/kayttajaryhmat/lisaa' ? 'Active' : null } to='/kayttajaryhmat/lisaa'>
                                                Lisää uusi
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link className={ window.location.pathname === '/asetukset' ? 'Active' : null } to='/asetukset'>
                                    Asetukset
                                </Link>
                            </li>
                        </ul>
                        <ul className='NavRight'>
                            <li>
                                <Link onClick={ () => logout() } to='#'>
                                    Kirjaudu ulos
                                </Link>
                            </li>
                        </ul>
                    </div>
                :
                    mobileMenuOpen === false ?
                        <div className='MobileToggle'>
                            <button onClick={ () => setMobileMenuOpen(true) }>
                                <FontAwesomeIcon icon='bars' />
                            </button>
                        </div>
                    :
                        <div>
                            <div className='MobileToggle'>
                                <button onClick={ () => setMobileMenuOpen(false) }>
                                    <FontAwesomeIcon icon='times' />
                                </button>
                            </div>
                            <div className='MobileNav'>
                                <ul>
                                    <li>
                                        <Link className={ window.location.pathname === '/' ? 'Active' : null } to='/'>
                                            Etusivu
                                        </Link>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/ohjeet' ? 'Active' : null } to='/ohjeet'>
                                                Työohjeet
                                            </Link>
                                            { activeDropdown === 'ohjeet' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('ohjeet') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'ohjeet' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/ohjeet/lisaa' ? 'Active' : null } to='/ohjeet/lisaa'>
                                                        Lisää uusi
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/ohjeet/kategoriat' ? 'Active' : null } to='/ohjeet/kategoriat'>
                                                        Kategoriat
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/ohjeet/oireet' ? 'Active' : null } to='/ohjeet/oireet'>
                                                        Oireet
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/avainsanat' ? 'Active' : null } to='/avainsanat'>
                                                        Avainsanat
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/tuotteet' ? 'Active' : null } to='/tuotteet'>
                                                Tuotteet
                                            </Link>
                                            { activeDropdown === 'tuotteet' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('tuotteet') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'tuotteet' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/tuotteet/lisaa' ? 'Active' : null } to='/tuotteet/lisaa'>
                                                        Lisää uusi
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/tuotteet/kategoriat' ? 'Active' : null } to='/tuotteet/kategoriat'>
                                                        Kategoriat
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/tuotteet/yksikot' ? 'Active' : null } to='/tuotteet/yksikot'>
                                                        Yksiköt
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <Link className={ window.location.pathname === '/vikailmoitukset' ? 'Active' : null } to='/vikailmoitukset'>
                                            Vikailmoitukset
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={ window.location.pathname === '/tilaukset' ? 'Active' : null } to='/tilaukset'>
                                            Tilaukset
                                        </Link>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/toimintatavat' ? 'Active' : null } to='/toimintatavat'>
                                                Toimintatavat
                                            </Link>
                                            { activeDropdown === 'toimintatavat' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('toimintatavat') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'toimintatavat' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/toimintatavat/lisaa' ? 'Active' : null } to='/toimintatavat/lisaa'>
                                                        Lisää uusi
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/toimintatavat/kategoriat' ? 'Active' : null } to='/toimintatavat/kategoriat'>
                                                        Kategoriat
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/kiinteistot' ? 'Active' : null } to='/kiinteistot'>
                                                Kiinteistot
                                            </Link>
                                            { activeDropdown === 'kiinteistot' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('kiinteistot') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'kiinteistot' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kiinteistot/kategoriat' ? 'Active' : null } to='/kiinteistot/kategoriat'>
                                                        Kategoriat
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kiinteistot/kuvat' ? 'Active' : null } to='/kiinteistot/kuvat'>
                                                        Kuvat
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kiinteistot/tiedostot' ? 'Active' : null } to='/kiinteistot/tiedostot'>
                                                        Tiedostot
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kiinteistot/videot' ? 'Active' : null } to='/kiinteistot/videot'>
                                                        Videot
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/kayttajat' ? 'Active' : null } to='/kayttajat'>
                                                Käyttäjät
                                            </Link>
                                            { activeDropdown === 'kayttajat' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('kayttajat') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'kayttajat' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kayttajat/lisaa' ? 'Active' : null } to='/kayttajat/lisaa'>
                                                        Lisää uusi
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='DropdownContainer'>
                                            <Link className={ window.location.pathname === '/kayttajaryhmat' ? 'Active' : null } to='/kayttajaryhmat'>
                                                Käyttäjäryhmät
                                            </Link>
                                            { activeDropdown === 'ryhmat' ?
                                                <button onClick={ () => setActiveDropdown('') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-down' />
                                                </button>
                                            :
                                                <button onClick={ () => setActiveDropdown('ryhmat') } className='ToggleDropdown'>
                                                    <FontAwesomeIcon icon='chevron-right' />
                                                </button>
                                            }
                                            <ul className={ activeDropdown === 'ryhmat' ? 'NavDropdown' : 'NavDropdown Hidden' }>
                                                <li>
                                                    <Link className={ window.location.pathname === '/kayttajaryhmat/lisaa' ? 'Active' : null } to='/kayttajaryhmat/lisaa'>
                                                        Lisää uusi
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <Link className={ window.location.pathname === '/asetukset' ? 'Active' : null } to='/asetukset'>
                                            Asetukset
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ () => logout() } to='#'>
                                            Kirjaudu ulos
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                }
                
            </Container>
        </div>
    )
}