const ProductValidation = (name, machineName, responsibility, productType, time, unit, price, selectedCategories) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        },
        machineName: {
            condition: machineName,
            message: 'Koneluettava otsikko on pakollinen kenttä'
        },
        responsibility: {
            condition: responsibility && responsibility > 0,
            message: 'Vastuu on pakollinen kenttä'
        },
        productType: {
            condition: productType,
            message: 'Valitse tuotteen tyyppi'
        },
        time: {
            condition: productType === 1 ? time : true,
            message: 'Aika on pakollinen kenttä'
        },
        unit: {
            condition: productType === 2 ? unit : true,
            message: 'Valitse tuotteen yksikkö'
        },
        price: {
            condition: productType === 2 ? price : true,
            message: 'Valitse tuotteen yksikköhinta'
        },
        selectedCategories: {
            condition: selectedCategories && selectedCategories.length > 0,
            message: 'Valitse tuotteen kategoriat'
        }
    })

    return validation
}

export default ProductValidation