import { useContext, useState, useEffect } from 'react';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';

function TagsIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [tags, setTags] = useState([])
    const [filteredTags, setFilteredTags] = useState([])
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/tags`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setTags(res.data)
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        tags.map(tag => {
            if (!filter || tag.name.toLowerCase().includes(filter.toLowerCase())) {
                return filtered.push(tag)
            } else {
                return null
            }
        })
        setFilteredTags(filtered)
    }, [filter, tags])

    useEffect(() => {
        setFirstItem(0)
    }, [filter])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Avainsanat</h1>
            <Button 
                action = { () => props.history.push('/avainsanat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            { tags &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredTags.slice(0).splice(firstItem, itemsToShow).map(tag => {
                            return (
                                <tr key={ tag.id }>
                                    <td>{ tag.name }</td>
                                    <td>{ `${new Date(tag.created_at).getDate()}.${new Date(tag.created_at).getMonth() + 1}.${new Date(tag.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(tag.updated_at).getDate()}.${new Date(tag.updated_at).getMonth() + 1}.${new Date(tag.updated_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/avainsanat/muokkaa/${tag.id}`}>Muokkaa</NavLink>
                                        <NavLink to={`/avainsanat/poista/${tag.id}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredTags.length }
                filter = { filter }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default TagsIndex