const HouseImagesValidation = (houseID, imageID) => {
    const validation = ({
        houseID: {
            condition: houseID,
            message: 'Valitse kiinteistö'
        },
        imageID: {
            condition: imageID,
            message: 'Valitse lisättävä kuva'
        }
    })

    return validation
}

export default HouseImagesValidation