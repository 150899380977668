const HouseVideosValidation = (houseID, video) => {
    const validation = ({
        houseID: {
            condition: houseID,
            message: 'Valitse kiinteistö'
        },
        video: {
            condition: video,
            message: 'Valitse lisättävä video'
        }
    })

    return validation
}

export default HouseVideosValidation