import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Button from '../../components/formElements/button/button';
import Modal from '../../components/UI/modal/modal';
import Select from '../../components/formElements/select/select';
import Backdrop from '../../components/UI/backdrop/backdrop';
import Message from '../../components/message/message';
import OrderValidation from '../../validation/orderValidation';
import Spinner from '../../components/UI/spinner/spinner';

function ShowOrders(props) {
    const [order, setOrder] = useState('')
    const [products, setProducts] = useState([])
    const [groups, setGroups] = useState([])
    const [options, setOptions] = useState([])
    const [showEdit, setShowEdit] = useState(false)
    const [statuses, setStatuses] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const { user, message, setMessage } = useContext(AuthContext)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setOrder(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/products/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setProducts(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/groups/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setGroups(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/options/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setOptions(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/order-statuses`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setStatuses(res.data)
            })
        }
    }, [props.match.params.id, user])

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = OrderValidation(selectedStatus)
        let message = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
            }
        })

        if (message.length > 0) {
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            status_id: selectedStatus
        }

        setLoading(true)
        axios.patch(`${process.env.REACT_APP_API_PATH}/api/orders/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then((res) => {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/orders/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setOrder(res.data)
                setShowEdit(false)
                setLoading(false)
                setMessage({ message: 'Tilauksen muokkaaminen onnistui.', class: 'Success' })
                window.scrollTo({ top: 0, behavior: "smooth" })
            })
        }).catch(() => {
            setShowEdit(false)
            setLoading(false)
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { !loading ?
                <React.Fragment>
                    { message &&
                        <Message 
                            classes = { message.class }
                            message = { message.message }
                            closeMessage = { () => setMessage(null) }
                        />
                    }
                    { showEdit &&
                        <React.Fragment>
                            <Modal close={ () => setShowEdit(false) }>
                                <h2>Muokkaa tilauksen tilaa</h2>
                                <Select 
                                    name = 'selectedStatus'
                                    value = { selectedStatus }
                                    options = { statuses }
                                    handlechange = { (e) => setSelectedStatus(e.target.children[e.target.selectedIndex].value) }
                                    placeholder = { 'Tilauksen tila' }
                                />
                                <Button 
                                    action = { (e) => handleFormValidation(e) }
                                    type = 'btn btn-primary'
                                    title = 'Tallenna'
                                />
                            </Modal>
                            <Backdrop action={ () => setShowEdit(false) } />
                        </React.Fragment>
                    }
                    { order &&
                        <div>
                            <h1>Tilaukset tiedot</h1>
                            <div style={{ margin: '30px 0', background: '#D3E0EB', padding: '15px' }}>
                                <p><strong>Tilausnumero:</strong> {order.order_number }</p>
                                <p><strong>Taloyhtiö:</strong> { order.house }</p>
                                <p><strong>Osoite:</strong> { order.address }</p>
                                <p><strong>Asunnon numero:</strong> { order.house_number }</p>
                                <p><strong>Tilaajan nimi:</strong> { order.name }</p>
                                <p><strong>Tilaajan puhelinnumero:</strong> { order.phone }</p>
                                <p><strong>Tilaajan sähköpostiosoite:</strong> { order.email }</p>
                                <p><strong>Hinta:</strong> { order.price } €</p>
                                <p><strong>Tila:</strong> { order.order_status }</p>
                                <p><strong>Toive työn suorituspäivämäärästä:</strong> { order.date_from && `${new Date(order.date_from).getDate()}.${new Date(order.date_from).getMonth() + 1}.${new Date(order.date_from).getFullYear()}-${new Date(order.date_to).getDate()}.${new Date(order.date_to).getMonth() + 1}.${new Date(order.date_to).getFullYear()}` }</p>
                                <p><strong>Toive suoritusajankohdasta:</strong> { order.time_of_day && order.time_of_day }</p>
                                <p><strong>Saako sisään mennä yleisavaimella?</strong> { order.public_key === 1 ? 'Kyllä' : 'Ei' }</p>
                                <p><strong>Onko asunnossa eläimiä?</strong> { order.animals_in_house === 1 ? 'Kyllä' : 'Ei' }</p>
                                <p><strong>Tilattu:</strong> { `${new Date(order.created_at).getDate()}.${new Date(order.created_at).getMonth() + 1}.${new Date(order.created_at).getFullYear()} ${new Date(order.created_at).getHours()}:${new Date(order.created_at).getMinutes() <= 9 ? '0' + new Date(order.created_at).getMinutes() : new Date(order.created_at).getMinutes()}` }</p>
                            </div>
                        </div>
                    }
                    <h2>Tuotteet</h2>
                    { products && products.length > 0 &&
                        products.map(product => {
                            return (
                                <div key={ product.id } style={{ margin: '30px 0', background: '#D3E0EB', padding: '15px' }}>
                                    <p><strong>{ product.name }</strong></p>
                                    <p><strong>Määrä:</strong> { product.amount }</p>
                                    <p><strong>Vastuu:</strong> { product.house_responsibility ? 'Taloyhtiö' : product.responsibility}</p>
                                    <p><strong>Lisätietoja:</strong> { product.description }</p>
                                    { groups && groups.length > 0 &&
                                        groups.filter(i => i.product_id === product.id).map(group => {
                                            return (
                                                <React.Fragment key={ 'group_' + group.id }>
                                                    <p><strong>{ group.name }:</strong></p>
                                                    { options && options.length > 0 &&
                                                        options.filter(i => i.group_id === group.id).map(option => {
                                                            return <p key={ 'option_' + option.id }>{ option.name }</p>
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <Button 
                        action = { () => setShowEdit(true) }
                        type = 'btn btn-primary'
                        title = 'Muokkaa tilauksen tilaa'
                    />
                </React.Fragment>
            :
                <Spinner />
            }
        </Layout>
    )
}

export default ShowOrders