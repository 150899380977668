import React from 'react';

import './imagePreview.scss';
import Button from '../button/button';

const ImagePreview = (props) => (
    <div className='ImagePreview'>
        <img src={ props.image } alt='preview' />
        <div>
            <Button 
                action = { props.removeImage }
                type = 'btn btn-danger'
                title = 'Poista kuva'
            />
        </div>
    </div>
)

export default ImagePreview