import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';

function ProductCategoriesIndex(props) {
    const { message, setMessage } = useContext(AuthContext)
    const [categories, setCategories] = useState('')
    const [filter, setFilter] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/products/categories`).then(res => {
            setCategories(res.data)
        })
    }, [])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Tuotekategoriat</h1>
            <Button 
                action = { () => props.history.push('/tuotteet/kategoriat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            { categories &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Lisätty</th>
                            <th>Muokattu</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { categories.map(category => {
                            if (!filter || category.name.toLowerCase().includes(filter.toLowerCase())) {
                                return (
                                    <tr key={ category.id }>
                                        <td>{ category.name }</td>
                                        <td>{ `${new Date(category.created_at).getDate()}.${new Date(category.created_at).getMonth() + 1}.${new Date(category.created_at).getFullYear()}` }</td>
                                        <td>{ `${new Date(category.updated_at).getDate()}.${new Date(category.updated_at).getMonth() + 1}.${new Date(category.updated_at).getFullYear()}` }</td>
                                        <td><NavLink to={`/tuotteet/kategoriat/muokkaa/${category.id}`}>Muokkaa</NavLink>
                                        <NavLink to={`/tuotteet/kategoriat/poista/${category.id}`}>Poista</NavLink></td>
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        })}
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default ProductCategoriesIndex