import React, { useContext, useState, useEffect } from 'react';
import './linkBlock.scss';
import GuideContext from '../../../stores/guideContext';
import Input from '../../formElements/input/input';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LinkBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const [label, setLabel] = useState('')
    const [link, setLink] = useState('')
    const [privateLink, setPrivateLink] = useState(false)
    const [draggable, setDraggable] = useState(false)
    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')

    useEffect(() => {
        if (props.initialValue) {
            setLabel(props.initialValue.label)
            setLink(props.initialValue.link)
            props.initialValue.privateLink === 'true' ? setPrivateLink(true) : setPrivateLink(false)
        }
    }, [props.initialValue])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    useEffect(() => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"link":"${link ? link : '#'}", "label":"${label ? label : link}", "privateLink":"${privateLink}"}`, type: 'link-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [label, link, privateLink, props.id, props.parent, setPageContent])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'link-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    return (
        <div 
            className = 'LinkBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { 'Linkki ' + count }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            {label ?
                <a href={ link } target='_blank' rel='noreferrer'>{ label }</a>
            :
                <a href={ link } target='_blank' rel='noreferrer'>{ link }</a>
            }
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'link'
                            title = 'Linkki'
                            value = { link }
                            placeholder = 'Linkki'
                            handlechange = { (e) => setLink(e.target.value)}
                        />
                        <Input 
                            inputtype = 'text'
                            name = 'label'
                            title = 'Otsikko'
                            value = { label }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setLabel(e.target.value)}
                        />
                        <div className='Checkbox'>
                            <input 
                                type = 'checkbox'
                                id = { 'link_checkbox_' + props.id } 
                                value = { privateLink } 
                                name = 'privateLink' 
                                checked = { privateLink ? true : false } 
                                onChange = { () => setPrivateLink(!privateLink) } 
                            />
                            <label htmlFor={ 'link_checkbox_' + props.id }>Vain työntekijöille</label>
                        </div>
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default LinkBlock