import { createContext, useState, useCallback } from "react";

const ProductContext = createContext({
    optionInputs: null,
    setOptionInputs: () => {},
    optionValues: null,
    setOptionValues: () => {},
    houseInputs: null,
    setHouseInputs: () => {},
    houseValues: null,
    setHouseValues: () => {},
    handleSetOptions: () => {},
    handleSetHouses: () => {},
    getNextKey: () => {},
    getNextHouseKey: () => {},
    getNextId: () => {},
    getNextHouseId: () => {},
    getNextGroup: () => {},
    removeOption: () => {},
    moveOptions: () => {},
    moveOption: () => {},
    removeHouse: () => {},
    clearOptions: () => {}
})

export const ProductContextProvider = ({ children }) => {
    const [optionInputs, setOptionInputs] = useState([])
    const [optionValues, setOptionValues] = useState([])
    const [houseInputs, setHouseInputs] = useState([])
    const [houseValues, setHouseValues] = useState([])

    const clearOptions = useCallback(() => {
        setOptionInputs([])
        setOptionValues([])
        setHouseInputs([])
        setHouseValues([])
    }, [])

    const removeOption = id => {
        const inputIndex = optionInputs.findIndex(i => parseInt(i.key) === parseInt(id))
        const valueIndex = optionValues.findIndex(i => parseInt(i.id) === parseInt(id))
        setOptionInputs(prevState => [...prevState.slice(0, inputIndex), ...prevState.slice(inputIndex + 1)])
        setOptionValues(prevState => [...prevState.slice(0, valueIndex), ...prevState.slice(valueIndex + 1)])
    }

    const moveOptions = (id, direction) => {
        const inputIndex = optionInputs.findIndex(i => parseInt(i.key) === parseInt(id))
        const input = optionInputs[inputIndex]
        const valueIndex = optionValues.findIndex(i => parseInt(i.id) === parseInt(id))
        const value = optionValues[valueIndex]

        const inputsCopy = optionInputs.slice(0)
        inputsCopy.splice(inputIndex, 1)

        const valuesCopy = optionValues.slice(0)
        valuesCopy.splice(valueIndex, 1)

        if (direction === 'up') {
            inputsCopy.splice(inputIndex - 1, 0, input)
            valuesCopy.splice(valueIndex - 1, 0, value)
        } else if (direction === 'down') {
            inputsCopy.splice(inputIndex + 1, 0, input)
            valuesCopy.splice(valueIndex + 1, 0, value)
        }

        setOptionInputs(inputsCopy)
        setOptionValues(valuesCopy)
    }

    const moveOption = (groupId, optionId, direction) => {
        const valueIndex = optionValues.findIndex(i => parseInt(i.id) === parseInt(groupId))
        const valuesCopy = optionValues.slice(0)
        const valueOptions = valuesCopy[valueIndex].options
        const valueOptionIndex = valueOptions.findIndex(i => i.id === optionId)
        const valueOption = valueOptions[valueOptionIndex]
        valueOptions.splice(valueOptionIndex, 1)

        if (direction === 'up') {
            valueOptions.splice(valueOptionIndex - 1, 0, valueOption)
        } else if (direction === 'down') {
            valueOptions.splice(valueOptionIndex + 1, 0, valueOption)
        }
        
        setOptionValues(valuesCopy)
    }

    const removeHouse = id => {
        const inputIndex = houseInputs.findIndex(i => parseInt(i.key) === parseInt(id))
        const valueIndex = houseValues.findIndex(i => parseInt(i.id) === parseInt(id))
        setHouseInputs(prevState => [...prevState.slice(0, inputIndex), ...prevState.slice(inputIndex + 1)])
        setHouseValues(prevState => [...prevState.slice(0, valueIndex), ...prevState.slice(valueIndex + 1)])
    }

    const handleSetOptions = (input, value) => {
        setOptionInputs(prevState => [...prevState, input])

        setOptionValues(prevState => [...prevState, value])
    }

    const handleSetHouses = (input, value) => {
        setHouseInputs(prevState => [...prevState, input])

        setHouseValues(prevState => [...prevState, value])
    }

    const getNextKey = () => {
        return optionInputs.length > 0 ? parseInt(optionInputs[optionInputs.length - 1].key) + 1 : 1
    }

    const getNextHouseKey = () => {
        return houseInputs.length > 0 ? parseInt(houseInputs[houseInputs.length - 1].key) + 1 : 1
    }

    const getNextId = () => {
        return optionValues.length > 0 ? optionValues[optionValues.length - 1].id + 1 : 1
    }

    const getNextHouseId = () => {
        return houseValues.length > 0 ? houseValues[houseValues.length - 1].id + 1 : 1
    }

    const getNextGroup = () => {
        return optionInputs.length > 0 ? parseInt(optionInputs[optionInputs.length - 1].props.group_id) + 1 : 1
    }

    const context = { optionInputs, setOptionInputs, optionValues, setOptionValues, handleSetOptions, getNextKey, getNextId, getNextGroup, clearOptions, removeOption, moveOptions, moveOption, houseInputs, setHouseInputs, houseValues, setHouseValues, handleSetHouses, removeHouse, getNextHouseKey, getNextHouseId }
    
    return (
        <ProductContext.Provider value={context}>
            { children }
        </ProductContext.Provider>
    )
}

export default ProductContext