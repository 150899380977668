import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import Select from '../../components/formElements/select/select';
import Pager from '../../components/pager/pager';

function ProductsIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Poista' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [filter, setFilter] = useState('')
    const [categories, setCategories] = useState('')
    const [productCategories, setProductCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getProducts() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/products`).then(res => {
            setProducts(res.data)
        })
    }

    useEffect(() => {
        getProducts()

        axios.get(`${process.env.REACT_APP_API_PATH}/api/products/categories`).then(res => {
            setCategories(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_categories`).then(res => {
            setProductCategories(res.data)
        })
    }, [])

    useEffect(() => {
        let filtered = []
        products.map(product => {
            if (((!filter || product.name.toLowerCase().includes(filter.toLowerCase())) && (!selectedCategory || productCategories.filter(i => i.product_id === product.id && i.product_category_id === selectedCategory).length > 0))) {
                return filtered.push(product)
            } else {
                return null
            }
        })
        setFilteredProducts(filtered)
    }, [filter, selectedCategory, productCategories, products])

    useEffect(() => {
        setFirstItem(0)
    }, [filter, selectedCategory])

    function handleProductSelection(id) {
        if (selectedProducts.includes(id)) {
            setSelectedProducts(selectedProducts.filter(i => i !== id))
        } else {
            setSelectedProducts([...selectedProducts, id])
        }
    }

    function selectAllProducts() {
        if (document.getElementById('SelectAllProducts').checked) {
            let productArray = []
            document.querySelectorAll('.SelectProduct').forEach(checkbox => {
                checkbox.checked = true
                productArray.push(parseInt(checkbox.value))
            })
            setSelectedProducts(productArray)
        } else {
            document.querySelectorAll('.SelectProduct').forEach(checkbox => {
                checkbox.checked = false
                setSelectedProducts([])
            })
        }
    }

    function unSelectAllProducts() {
        document.getElementById('SelectAllProducts').checked = false
        document.querySelectorAll('.SelectProduct').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedProducts([])
    }

    function handleMassEdit() {
        if (selectedProducts.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse tuotteet' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/products/delete`, {
                products: selectedProducts
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Tuotteet poistettu' })
                getProducts()
                unSelectAllProducts()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Tuotteet</h1>
            <Button 
                action = { () => props.history.push('/tuotteet/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <Button 
                action = { () => props.history.push('/tuotteet/kategoriat') }
                type = 'btn btn-primary'
                title = 'Kategoriat'
            />
            <Button 
                action = { () => props.history.push('/tuotteet/yksikot') }
                type = 'btn btn-primary'
                title = 'Yksiköt'
            />
            { categories &&
                <div className='GuideFilters'>
                    <Select 
                        name = 'massEdit'
                        value = { massEdit }
                        options = { massEditOptions }
                        handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = 'Massamuokkaus'
                    />
                    <Button
                        action = { () => handleMassEdit() }
                        type = 'btn btn-success btn-mass-edit'
                        title = 'Suorita'
                    />
                    <Select 
                        name = 'selectedCategory'
                        value = { selectedCategory }
                        options = { categories }
                        handlechange = { (e) => setSelectedCategory(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Kategoria' }
                    />
                    <input style={{ float: 'right', padding: '10px', marginTop: '15px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
                </div>
            }
            { products &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllProducts" onChange={() => selectAllProducts()} type='checkbox' /></th>
                            <th>Nimi</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredProducts.slice(0).splice(firstItem, itemsToShow).map(product => {
                            return (
                                <tr key={ product.id }>
                                    <td><input className="SelectProduct" onChange={ () => handleProductSelection(product.id)
                                    } type='checkbox' value={ product.id } checked={ selectedProducts.includes(product.id) ? true : false } /></td>
                                    <td>{ product.name }</td>
                                    <td>{ `${new Date(product.created_at).getDate()}.${new Date(product.created_at).getMonth() + 1}.${new Date(product.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(product.updated_at).getDate()}.${new Date(product.updated_at).getMonth() + 1}.${new Date(product.updated_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/tuotteet/muokkaa/${product.id}`}>Muokkaa</NavLink>
                                        <NavLink to={`/tuotteet/poista/${product.id}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredProducts.length }
                filter = { filter }
                filter2 = { selectedCategory }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default ProductsIndex