import { useContext, useState, useEffect } from 'react';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';
import GuideContext from '../../stores/guideContext';
import Select from '../../components/formElements/select/select';
import Pager from '../../components/pager/pager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PoliciesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const { clearState } = useContext(GuideContext)
    const [policies, setPolicies] = useState([])
    const [filteredPolicies, setFilteredPolicies] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Julkaise' },
        { id: 2, name: 'Poista Julkaisu' },
        { id: 3, name: 'Poista Ohje' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [categories, setCategories] = useState('')
    const [policyCategories, setPolicyCategories] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [filter, setFilter] = useState('')
    const [orderByName, setOrderByName] = useState('asc')
    const [orderByPublished, setOrderByPublished] = useState('desc')
    const [orderByCreated, setOrderByCreated] = useState('asc')
    const [orderByUpdated, setOrderByUpdated] = useState('asc')
    const [orderByWeight, setOrderByWeight] = useState('asc')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getPolicies() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setPolicies(res.data)
        })
    }

    useEffect(() => {
        if (user && user.token) {
            getPolicies()
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/policy_categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setPolicyCategories(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                const sortedByParent = res.data.sort((a, b) => a.parent_category > b.parent_category ? 1 : -1)
                const sortedCategories = []
                sortedByParent.map(category => {
                    if (!category.parent_category) {
                        category.level = 1
                        return sortedCategories.push(category)
                    } else {
                        category.level = 2
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)
                    }
                })
                setCategories(sortedCategories.reverse())
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        policies.map(policy => {
            if (((!filter || policy.name.toLowerCase().includes(filter.toLowerCase())) && (!selectedCategory || policyCategories.filter(i => i.policy_id === policy.id && i.category_id === selectedCategory).length > 0))) {
                return filtered.push(policy)
            } else {
                return null
            }
        })
        setFilteredPolicies(filtered)
    }, [filter, selectedCategory, policyCategories, policies])

    useEffect(() => {
        setFirstItem(0)
    }, [filter, selectedCategory])

    function addNewGuide() {
        clearState()
        props.history.push('/toimintatavat/lisaa')
    }

    function orderGuidesByName() {
        let guidesCopy = policies.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.name < b.name) {
                return orderByName === 'asc' ? - 1 : 1
            } else if (a.name > b.name) {
                return orderByName === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByName === 'asc' ? setOrderByName('desc') : setOrderByName('asc')
        
        setPolicies(guidesCopy)
    }

    function orderGuidesByPublished() {
        let guidesCopy = policies.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.published < b.published) {
                return orderByPublished === 'asc' ? - 1 : 1
            } else if (a.published > b.published) {
                return orderByPublished === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByPublished === 'asc' ? setOrderByPublished('desc') : setOrderByPublished('asc')
        
        setPolicies(guidesCopy)
    }

    function orderGuidesByCreated() {
        let guidesCopy = policies.splice(0)

        guidesCopy.sort((a, b) => {
            if (new Date(a.created_at) < new Date(b.created_at)) {
                return orderByCreated === 'asc' ? - 1 : 1
            } else if (new Date(a.created_at) > new Date(b.created_at)) {
                return orderByCreated === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByCreated === 'asc' ? setOrderByCreated('desc') : setOrderByCreated('asc')
        
        setPolicies(guidesCopy)
    }

    function orderGuidesByUpdated() {
        let guidesCopy = policies.splice(0)

        guidesCopy.sort((a, b) => {
            if (new Date(a.updated_at) < new Date(b.updated_at)) {
                return orderByUpdated === 'asc' ? - 1 : 1
            } else if (new Date(a.updated_at) > new Date(b.updated_at)) {
                return orderByUpdated === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByUpdated === 'asc' ? setOrderByUpdated('desc') : setOrderByUpdated('asc')
        
        setPolicies(guidesCopy)
    }

    function orderGuidesByWeight() {
        let guidesCopy = policies.splice(0)

        guidesCopy.sort((a, b) => {
            if (a.weight < b.weight) {
                return orderByWeight === 'asc' ? - 1 : 1
            } else if (a.weight > b.weight) {
                return orderByWeight === 'asc' ? 1 : - 1
            } else {
                return 0
            }
        })

        orderByWeight === 'asc' ? setOrderByWeight('desc') : setOrderByWeight('asc')
        
        setPolicies(guidesCopy)
    }

    function handleItemSelection(id) {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(i => i !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    function selectAllItems() {
        if (document.getElementById('SelectAllItems').checked) {
            let itemArray = []
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = true
                itemArray.push(parseInt(checkbox.value))
            })
            setSelectedItems(itemArray)
        } else {
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = false
                setSelectedItems([])
            })
        }
    }

    function unSelectAllItems() {
        document.getElementById('SelectAllItems').checked = false
        document.querySelectorAll('.SelectItem').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedItems([])
    }

    function handleMassEdit() {
        if (selectedItems.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse ohjeet' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/policies/publish`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeet julkaistu' })
                getPolicies()
                unSelectAllItems()
            })
        } else if (massEdit === 2) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/policies/unpublish`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeiden tila muutettu julkaisemattomaksi' })
                getPolicies()
                unSelectAllItems()
            })
        } else if (massEdit === 3) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/policies/delete`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Ohjeet poistettu' })
                getPolicies()
                unSelectAllItems()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Organisaation toimintatavat</h1>
            <Button 
                action = { addNewGuide }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <Button 
                action = { () => props.history.push('/toimintatavat/kategoriat') }
                type = 'btn btn-primary'
                title = 'Kategoriat'
            />
            { categories &&
                <div className='GuideFilters'>
                    <Select 
                        name = 'massEdit'
                        value = { massEdit }
                        options = { massEditOptions }
                        handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = 'Massamuokkaus'
                    />
                    <Button
                        action = { () => handleMassEdit() }
                        type = 'btn btn-success btn-mass-edit'
                        title = 'Suorita'
                    />
                    <Select 
                        name = 'selectedCategory'
                        value = { selectedCategory }
                        options = { categories }
                        handlechange = { (e) => setSelectedCategory(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Kategoria' }
                    />
                    <input style={{ float: 'right', padding: '10px', marginTop: '15px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
                </div>
            }
            { policies &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllItems" onChange={() => selectAllItems()} type='checkbox' /></th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByName() }>
                                Otsikko { orderByName === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByPublished() }>
                                Julkaistu { orderByPublished === 'desc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByCreated() }>
                                Lisätty { orderByCreated === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByUpdated() }>
                                Päivitetty { orderByUpdated === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th style={{ cursor: 'pointer' }} onClick={ () => orderGuidesByWeight() }>
                                Paino { orderByWeight === 'asc' ? 
                                    <FontAwesomeIcon icon='chevron-down' /> 
                                :   
                                    <FontAwesomeIcon icon='chevron-up' /> 
                                }
                            </th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredPolicies.slice(0).splice(firstItem, itemsToShow).map(policy => {
                            return (
                                <tr key={ policy.id }>
                                    <td><input className="SelectItem" onChange={ () => handleItemSelection(policy.id)
                                    } type='checkbox' value={ policy.id } checked={ selectedItems.includes(policy.id) ? true : false } /></td>
                                    <td>{ policy.name }</td>
                                    <td>{ policy.published === 1 ? 'Kyllä' : 'Ei' }</td>
                                    <td>{ `${new Date(policy.created_at).getDate()}.${new Date(policy.created_at).getMonth() + 1}.${new Date(policy.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(policy.updated_at).getDate()}.${new Date(policy.updated_at).getMonth() + 1}.${new Date(policy.updated_at).getFullYear()}` }</td>
                                    <td>{ policy.weight }</td>
                                    <td>
                                        <NavLink to={`/toimintatavat/muokkaa/${policy.machine_name}`}>Muokkaa</NavLink>
                                        <NavLink to={`/toimintatavat/${policy.machine_name}`}>Näytä</NavLink>
                                        <NavLink to={`/toimintatavat/poista/${policy.machine_name}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredPolicies.length }
                filter = { filter }
                filter2 = { selectedCategory }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default PoliciesIndex