import React from 'react';

import './button.scss';

const Button = props => {
	return (
		<button className={ props.type } onClick={ props.action }>
			{ props.title }
		</button>
	)
}

export default Button