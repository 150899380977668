import React from 'react';
import { Link } from 'react-router-dom';

import './dashboardItem.scss';

const DashboardItem = (props) => (
    <div className='DashboardItemWrapper'>
        <Link to={ props.link }>
            <div className='DashboardItem'>
                { props.label }
            </div>
        </Link>
    </div>
)

export default DashboardItem