import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import TagValidation from '../../validation/tagValidation';

function EditTags(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/tags/id/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setName(res.data.name)
            })
        }
    }, [user, props.match.params.id])

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = TagValidation(name)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/tags/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Avainsanan muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Muokkaa avainsanaa</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'name'
                    title = 'Avainsana'
                    value = { name }
                    placeholder = 'Avainsana'
                    handlechange = { (e) => setName(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default EditTags