const UnitValidation = (name) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Yksikkö on pakollinen kenttä'
        }
    })

    return validation
}

export default UnitValidation